import React, { useEffect, useRef, useState } from 'react'
import iconCompany from "../Images/icons/icon-company.png"
import iconPerson from "../Images/icons/icon-user.png"
import ProfileTitleCard from './Cards/ProfileTitleCard'
import iconProfile from "../Images/icons/icon-male-user.png"
import ProfileFormCard from './Cards/ProfileFormCard'
import SideCardProfileInfo from './Cards/SideCardProfileInfo'
import profilePhoto from "../Images/BG-Images/per.png"
import companyProfilePic from "../Images/BG-Images/company-image.png"
import { useSelector } from 'react-redux'
import PopUpCardForChoose from './Cards/PopUpCardForChoose'
import EditableFormProfile from './Cards/EditableFormProfile'
import iconEdit from "../Images/icons/icon-edit.png"
import { useNavigate } from 'react-router-dom'
import VerificationCard from './Cards/VerificationCard'
import mobileVerify from "../Images/BG-Images/mobileVerify.png"
import iconOTP from "../Images/BG-Images/otpIcon.png"
import iconMobile from "../Images/icons/iconMobile.png"
import iconEmail from "../Images/icons/iconEmail.png"
import imageEmail from "../Images/BG-Images/emailImage.png"
import verifiedIcon from "../Images/icons/icon-verifiedNew.png"
import animatedLoader from "../Images/Animation-gifs/AnimationLoading.gif"
import { DotLottieReact } from '@lottiefiles/dotlottie-react';
import loeader from "../Images/Animation-gifs/loader.lottie"
import { ethers } from "ethers"

export default function DashboardProfile() {
  const Udid = useSelector((state) => state.DidReducer)
  const navigate = useNavigate()
  // set owner detaisl 
  const [ownerDetails, setOwnerDetails] = useState(null)
  const avalibilityData = useSelector((state) => state.requireAvalibilityReducer)

  const [signer, setSigner] = useState(null)


  // user or company profile
  const [UorC, setUorC] = useState("")
  const [profile, setProfile] = useState("")
  const [togglePopup, setTogglePopup] = useState(false)

  // const user or company status fn
  const UorCfn = async () => {
    const p = new ethers.providers.Web3Provider(window.ethereum)
    const s = p.getSigner()
    const acc = await s.getAddress()
    try {
      const res = await fetch(process.env.REACT_APP_BACKEND_URL + `get-user-profile?OwnerDid=${Udid}&OwnerAddress=${acc}`)
      const resData = await res.json()
      setUorC(resData.AccountType) //set user or company 

      setOwnerDetails(resData)

    } catch (error) {
      console.log(error)
    }

  }

  // get account address
  const getAddress = async () => {
    const provider = new ethers.providers.Web3Provider(window.ethereum)
    const signer = provider.getSigner()
    const address = await signer.getAddress()
    setSigner(address)
  }


  // toggle blur md 
  const [blur, setBlur] = useState(false)

  // set user or company profile button fn 
  const setUserFn = () => {
    setTogglePopup(true)
    setBlur(true)
    setProfile("User")
  }

  const setCompanFn = () => {
    setTogglePopup(true)
    setBlur(true)
    setProfile("Company")
  }

  // accpet and deny fn
  const AcceptFn = async () => {
    setTogglePopup(false)
    setBlur(false)

    const res = await fetch(process.env.REACT_APP_BACKEND_URL + `update-user-profile`, {
      method: "PUT",
      'content-type': "application/json",
      body: JSON.stringify({
        "ownerDid": Udid,
        "AccountType": profile,
        "OwnerAddress": signer
      })
    })

    if (res.status === 200) {
      UorCfn();
    }

  }

  const DenyFn = () => {
    setTogglePopup(false)
    setBlur(false)
  }

  // if user not install meta mask navigate to dashboard 
  useEffect(() => {
    if (!window.ethereum) {
      navigate("/dashboard")
    }
    UorCfn()

    getAddress()
  }, [])


  // show and hide company form 
  const [toggleEditForm, setToggleEditForm] = useState(false)

  // popup btn for the edit form
  const EditFormFn = () => {
    UorCfn()
    setToggleEditForm(true)
    setBlur(true)

  }

  const submitEditFormUser = () => {
    setToggleEditForm(false)
    setBlur(false)

    setTimeout(() => {
      UorCfn()
    }, 2000);
  }

  // toggle verification state
  const [toggleVerification, setToggleVerification] = useState(false)
  const [toggleEmailVerification, setToggleEmailVerification] = useState(false)


  // toggle verification div
  const closeBtn = () => {
    setToggleVerification(false)
    setToggleEmailVerification(false)
    setBlur(false)
  }

  const toggleEmail = () => {
    console.log(ownerDetails.OwnerEmail)
    if (ownerDetails.OwnerEmail !== "") {
      setToggleVerification(false)
      setToggleEmailVerification(true)
      setBlur(true)
    }

  }

  const toggleMobile = () => {
    if (ownerDetails.PhoneNumber !== "") {
      setToggleVerification(true)
      setToggleEmailVerification(false)
      setBlur(true)
    }

  }

  useEffect(() => {
    verifyEmail()
  }, [])

  // email verified 
  const [verified, setVerified] = useState({ email: false, mobile: false })


  // get email verify or not 
  const verifyEmail = async () => {
    try {
      const res = await fetch(process.env.REACT_APP_BACKEND_URL + `get-email-verify?OwnerDid=${Udid}`)
      const verifyData = await res.json()
      setVerified((prev) => ({ ...prev, email: verifyData.is_verified }))

      const res2 = await fetch(process.env.REACT_APP_BACKEND_URL + `get-phone-number-verify?OwnerDid=${Udid}`)
      const verifyData2 = await res2.json()
      setVerified((prev) => ({ ...prev, mobile: verifyData2.verified }))
    } catch (error) {
      console.log(error)
    }
  }


  return (
    <section className={`relative h-auto w-full flex flex-col justify-center items-center ${!avalibilityData.allreadyRegisteredWallet && !avalibilityData.metamask && !avalibilityData.userAdded && !avalibilityData.noUser ? "" : ""}`}>
      {/* pop up div card */}
      {togglePopup && <PopUpCardForChoose Profile={profile} onBtnClick={DenyFn} onBtnClick2={AcceptFn} />}

      {/* user profile */}
      {(toggleEditForm && UorC === "User") &&
        <EditableFormProfile iconEdit={iconEdit} onFormBtnClick={submitEditFormUser} UorC={UorC} getDetails={ownerDetails}
          label_first_name={"Fisrt Name"}
          input_first_name={ownerDetails ? ownerDetails.FirstName : "Your first name"}
          lable_last_name={"Last Name"}
          input_last_name={ownerDetails ? ownerDetails.LastName : "Your last name"}
          label_city={"City"}
          input_city={ownerDetails ? ownerDetails.City : "Your city"}
          label_mobile_number={"Mobile Number"}
          country_code={ownerDetails && ownerDetails.CountryCode}
          input_mobile_number={ownerDetails ? ownerDetails.PhoneNumber : "Contact number"}
          label_addressLine1={"Address Line 1"}
          label_addressLine2={"Adress Line 2"}
          input_addressLine1={ownerDetails ? ownerDetails.AddressLine1 : "Address line 1"}
          input_addressLine2={ownerDetails ? ownerDetails.AddressLine2 : "Address line 2"}
          input_country={ownerDetails ? ownerDetails.Country : "Country"}
          input_email={ownerDetails ? ownerDetails.OwnerEmail : "Your email"}
          input_state={ownerDetails ? ownerDetails.State : "Your state"}
          input_zip_code={ownerDetails ? ownerDetails.City : "Zip code"}
          discription={ownerDetails ? ownerDetails.Description : "Discription here"}
        />}

      {/* company profile */}
      {(toggleEditForm && UorC === "Company") &&
        <EditableFormProfile iconEdit={iconEdit} onFormBtnClick={submitEditFormUser} UorC={UorC} getDetails={ownerDetails}
          label_first_name={"Company Name"}
          input_first_name={ownerDetails ? ownerDetails.CompanyName : "Your Company name"}
          lable_last_name={"Company Register No"}
          input_last_name={ownerDetails ? ownerDetails.CompanyRegno : "Your Company Register Number"}
          label_city={"City"}
          country_code={ownerDetails && ownerDetails.CountryCode}
          input_city={ownerDetails ? ownerDetails.City : "Your city"}
          label_mobile_number={"Company Number"}
          input_mobile_number={ownerDetails ? ownerDetails.PhoneNumber : "Contact number"}
          label_addressLine1={"Address Line 1"}
          label_addressLine2={"Adress Line 2"}
          input_addressLine1={ownerDetails ? ownerDetails.AddressLine1 : "Address line 1"}
          input_addressLine2={ownerDetails ? ownerDetails.AddressLine2 : "Address line 2"}
          input_country={ownerDetails ? ownerDetails.Country : "Country"}
          input_email={ownerDetails ? ownerDetails.OwnerEmail : "Your email"}
          input_state={ownerDetails ? ownerDetails.State : "Your state"}
          input_zip_code={ownerDetails ? ownerDetails.City : "Zip code"}
          discription={ownerDetails ? ownerDetails.Description : "Discription here"}

        />}

      {/* profile section heading */}
      <div className='w-full py-2 flex gap-2 items-center'>
        <img src={iconProfile} className='flex w-[25px]' alt="" />
        <h3 className='text-white text-[16px]'>PROFILE</h3>
      </div>

      {/* two profile cards company or person */}
      <div className={`flex gap-4 w-full h-auto p-2 ${blur ? 'blur-sm' : ''}`}>
        <button className='w-full' onClick={setUserFn} disabled={UorC !== "Pending"}>
          <ProfileTitleCard title={"For Personal"} Maintitle={"User Name"} Subtitle={"Personal Details"} CardColor={UorC === "User" ? true : false} Image={iconPerson} />
        </button>

        <button className='w-full' onClick={setCompanFn} disabled={UorC !== "Pending"}>
          <ProfileTitleCard title={"For Companies"} Maintitle={"Company"} Subtitle={"Company details"} CardColor={UorC === "Company" ? true : false} Image={iconCompany} />
        </button>


      </div>

      {/* message that select a profile to continue */}
      {UorC === "Pending" && <div className={`${blur ? 'blur-sm' : ''} flex w-[97%] justify-center mx-auto p-3 border-[1px] border-white/40 mt-4 rounded-lg`}>
        <h2 className='text-white/50 text-[20px] text-center'>"Please select one of the profiles that you wish to continue with. Once selected, it cannot be changed."</h2>
      </div>}

      {/* details profile cards */}
      <div className='w-full'>
        {(UorC !== "") ?
          <div className='text-white px-2 w-full flex-col gap-4 lg:flex-nowrap md:flex-nowrap sm:flex-wrap min-[320px]:flex-wrap'>
            {(UorC === "User") &&
              <SideCardProfileInfo
                image={profilePhoto} Name={ownerDetails ? ownerDetails.FirstName : "User Name"} Email={ownerDetails ? ownerDetails.OwnerEmail : "User Email"} blur={blur} Discription={ownerDetails ? ownerDetails.Description : "Discription"} />
            }

            {(UorC === "Company") &&
              <SideCardProfileInfo
                image={companyProfilePic} Name={ownerDetails ? ownerDetails.CompanyName : "Company Name"} Email={ownerDetails ? ownerDetails.OwnerEmail : "Company Email"} blur={blur} Discription={ownerDetails ? ownerDetails.Description : "Discription"} />
            }

            {(UorC === "User") && <ProfileFormCard onBtnClick={EditFormFn} blur={blur}
              label_first_name={"Fisrt Name"}
              input_first_name={ownerDetails ? ownerDetails.FirstName : "Your first name"}
              lable_last_name={"Last Name"}
              input_last_name={ownerDetails ? ownerDetails.LastName : "Your last name"}
              label_city={"City"}
              input_city={ownerDetails ? ownerDetails.City : "Your city"}
              label_mobile_number={"Mobile Number"}
              input_mobile_number={ownerDetails ? ownerDetails.PhoneNumber : "Contact number"}
              label_addressLine1={"Address Line 1"}
              label_addressLine2={"Adress Line 2"}
              input_addressLine1={ownerDetails ? ownerDetails.AddressLine1 : "Address line 1"}
              input_addressLine2={ownerDetails ? ownerDetails.AddressLine2 : "Address line 2"}
              input_country={ownerDetails ? ownerDetails.Country : "Country"}
              input_email={ownerDetails ? ownerDetails.OwnerEmail : "Your email"}
              input_state={ownerDetails ? ownerDetails.State : "Your state"}
              input_zip_code={ownerDetails ? ownerDetails.PostalCode : "Zip code"}
              discription={ownerDetails ? ownerDetails.Description : "Discription here"}
            />}

            {(UorC === "Company") &&
              <ProfileFormCard onBtnClick={EditFormFn} blur={blur}
                label_first_name={"Company Name"}
                input_first_name={ownerDetails ? ownerDetails.CompanyName : "Your Company name"}
                lable_last_name={"Company Register No"}
                input_last_name={ownerDetails ? ownerDetails.CompanyRegno : "Your Company Register Number"}
                label_city={"City"}
                input_city={ownerDetails ? ownerDetails.City : "Your city"}
                label_mobile_number={"Company Number"}
                input_mobile_number={ownerDetails ? ownerDetails.PhoneNumber : "Contact number"}
                label_addressLine1={"Address Line 1"}
                label_addressLine2={"Adress Line 2"}
                input_addressLine1={ownerDetails ? ownerDetails.AddressLine1 : "Address line 1"}
                input_addressLine2={ownerDetails ? ownerDetails.AddressLine2 : "Address line 2"}
                input_country={ownerDetails ? ownerDetails.Country : "Country"}
                input_email={ownerDetails ? ownerDetails.OwnerEmail : "Your email"}
                input_state={ownerDetails ? ownerDetails.State : "Your state"}
                input_zip_code={ownerDetails ? ownerDetails.City : "Zip code"}
                discription={ownerDetails ? ownerDetails.City : "Discription here"}
              />}

            {/* mobile and email verification toggle div */}
            {(UorC === "User" || UorC === "Company") && <div className={`${toggleVerification || toggleEmailVerification ? "pointer-events-none blur-sm" : ""} w-full justify-end px-2 flex py-3 gap-3`}>
              {/* verifed msg mobile */}
              {verified.email ?
                <div className='w-[150px] px-1 py-2 flex gap-2 items-center justify-center rounded-lg bg-green-500 animate__animated animate__fadeIn'>
                  <img src={verifiedIcon} className='w-[25px]' alt="" />
                  <h3 className='text-[#0F0F0F] font-semibold text-[14px]'>Email Verified</h3>
                </div>
                :
                <button onClick={toggleEmail} className='w-[150px] border border-white/40 px-1 py-2 flex gap-2 items-center justify-center rounded-lg hover:scale-105 hover:bg-bethel-green/70'>
                  <img src={iconEmail} className='w-[20px]' alt="" />
                  <h3 className='text-white text-[12px]'>Verify Email</h3>
                </button>
              }

              {/* verifed msg mobile */}
              {verified.mobile ?
                <div className='w-[150px] px-1 py-2 flex gap-2 items-center justify-center rounded-lg bg-green-500 animate__animated animate__fadeIn'>
                  <img src={verifiedIcon} className='w-[25px]' alt="" />
                  <h3 className='text-[#0F0F0F] font-semibold text-[14px]'>Mobile Verified</h3>
                </div>
                :
                <button onClick={toggleMobile} className='w-[150px] border border-white/40 px-1 py-2 flex gap-2 items-center justify-center rounded-lg hover:scale-105 hover:bg-bethel-green/70'>
                  <img src={iconMobile} className='w-[20px]' alt="" />
                  <h3 className='text-white text-[12px]'>Verify Mobile</h3>
                </button>
              }

            </div>
            }

          </div>
          :
          <div className='w-full flex justify-center h-fit items-center'>
            <div className='flex flex-col items-center relative'>
              {/* <img src={animatedLoader} className='opacity-100 h-[200px]' alt="" /> */}
              <DotLottieReact
                src={loeader}
                loop
                autoplay
              />

              <div className='text-white absolute bottom-8 tracking-widest animate-pulse'>
                <h3>Loading ...</h3>
              </div>
            </div>
          </div>
        }
      </div>




      {/* verification component */}
      {toggleVerification &&
        <VerificationCard
          img={mobileVerify}
          verify_msg={"Mobile Verification"}
          verify_paragrapgh={"Mobile number to verify"}
          img2={iconOTP}
          email_set={toggleEmailVerification}
          input_text={"Edit mobile number in profile"}
          verify_msg1={"OTP code sent"}
          verify_paragrapgh1={"Please enter your OTP code below"}
          closeBtn={closeBtn}
          updateVerify={verifyEmail}
          country_code={ownerDetails && ownerDetails.CountryCode}
          input_mobile_number={ownerDetails ? ownerDetails.PhoneNumber : "Contact number"}
          input_email={ownerDetails ? ownerDetails.OwnerEmail : "Your email"}
        />
      }

      {toggleEmailVerification &&
        <VerificationCard
          img={imageEmail}
          verify_msg={"Email Verification"}
          verify_paragrapgh={"Your Email Verfication"}
          img2={iconOTP}
          email_set={toggleEmailVerification}
          input_text={"Edit email in profile"}
          verify_msg1={"OTP code sent"}
          verify_paragrapgh1={"Please enter your OTP code below"}
          closeBtn={closeBtn}
          country_code={ownerDetails && ownerDetails.CountryCode}
          updateVerify={verifyEmail}
          input_mobile_number={ownerDetails ? ownerDetails.PhoneNumber : "Contact number"}
          input_email={ownerDetails ? ownerDetails.OwnerEmail : "Your email"}

        />
      }

    </section >
  )
}