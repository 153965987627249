import QRCode from "react-qr-code";
import React, { useRef } from "react";
import "../Login_Register/Login.css";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import globeImg from "../../Images/BG-Images/bgImageGloabe.png"
import didSlice from "../../reducers/didRedcuer";
import heroVid from "../../Images/Videos/green-bg.mp4";
import iconHelp from "../../Images/icons/iconHelp.png";
import iosQR from "../../Images/QrCodes/Screenshot_2024-01-31_at_15.57.35.png";
import androidQR from "../../Images/QrCodes/android.png";
import androidstore from "../../Images/icons/androidstore.png";
import applestore from "../../Images/icons/applestore.png";
import step1Ios from "../../Images/SS1/polygonIos.png";
import Image2 from "../../Images/Dashboard-images/2.webp";
import Image4 from "../../Images/Dashboard-images/4.webp";
import Image5 from "../../Images/Dashboard-images/5.png";
import Image7 from "../../Images/Dashboard-images/7.png";
import Image9 from "../../Images/Dashboard-images/9.png";
import Image11 from "../../Images/Dashboard-images/11.png";
import image1_1 from "../../Images/SS1/1.1.webp";
import image1_2 from "../../Images/SS1/1.2.webp";
import image1_3 from "../../Images/SS1/1.3.webp";
import image1_4 from "../../Images/SS1/1.4.webp";
import image1_5 from "../../Images/SS1/1.5.webp";
import image1_6 from "../../Images/SS1/1.6.webp";
import image1_7 from "../../Images/SS1/1.7.webp";
import logStep1Img from "../../Images/SS1/loginScanQr.png";
import iconclose from "../../Images/icons/icons-close.png"
import profileChange1 from "../../Images/SS1/loginProfileChange.png";
import profileChange from "../../Images/SS1/privateToOpen.png";
import singupQRImg from "../../Images/SS1/signUpQR.png";
import behtelIcon from "../../Images/BG-Images/bethel-new-logo.png"
import logStep5Img from "../../Images/SS1/approve.png";
import { useEffect, useState } from "react";
import loaderSearch from "../../Images/Animation-gifs/Ellipsis-11.gif";
import iconIoss from "../../Images/icons/icons8-ios-150.png";
import iconAndroid from "../../Images/icons/icons8-android-150.png";
import WalletAddressSlice from "../../reducers/WalletAddressSlice";
import robot from "../../Images/BG-Images/robot.png"
import warningImg from "../../Images/BG-Images/warning.png"
import $u from "../../utils/$u.js";
import "./../../index.css";
import Scene from "../3D/BethelLogo3D";
import { Canvas, useFrame } from "@react-three/fiber";
import { useGLTF, OrbitControls, Environment, Effects, Sky, Stars, useSelect } from '@react-three/drei';
import { Vector3 } from 'three';
import { Bloom, DepthOfField, EffectComposer, Noise } from "@react-three/postprocessing";
import InstallMetamaskComp from "./InstallMetamaskComp";
import UserValidation from "./UserValidation.jsx";
import WalletAddressValidation from "./WalletAddressValidation.jsx";
import LoginValidationCheck from "./LoginValidationCheck.jsx";
import BethelImg from "../../Images/BG-Images/bethel-new-logo.png"
import Typewriter from 'typewriter-effect';
import MetamaskInstall from "../Cards/MetamaskInstall.jsx";
const { ethers } = require("ethers");
const wc = require("../../witness/wintess_calcutlator.js");
const snarkjs = require("snarkjs");

const version = process.env.REACT_APP_BETHEL_VERSION


function CameraAnimation({ camera, onAnimationComplete }) {
  const startPosition = new Vector3(10, 0, 0);
  const endPosition = new Vector3(0, 0, 9);
  const duration = 7;
  const [animationComplete, setAnimationComplete] = useState(false);


  useFrame(({ clock }) => {
    try {
      const elapsedTime = clock.getElapsedTime();
      const lerpFactor = Math.min(elapsedTime / duration, 1);
      camera.position.lerpVectors(startPosition, endPosition, lerpFactor);
      camera.lookAt(0, 0, 0);

      if (lerpFactor === 1) {
        setAnimationComplete(true);
      }
    } catch (error) {
      console.log(error)
    }

  });

  useEffect(() => {
    if (animationComplete) {
      onAnimationComplete();
    }
  }, [animationComplete, onAnimationComplete]);

  return null;
}

function Login({ path }) {
  const cameraRef = useRef();
  const controlsRef = useRef();
  const [pos, setPos] = useState(false)

  useEffect(() => {
    if (cameraRef.current) {
      cameraRef.current.position.set(0, 2, 9);
    }
  }, []);

  const handleAnimationComplete = () => {
    if (controlsRef.current) {
      controlsRef.current.enabled = true; // Enable controls after animation
    }
    setPos(true)
  };

  useEffect(() => {
    if (controlsRef.current) {
      controlsRef.current.enabled = false; // Disable controls during animation
    }
  }, []);


  const Navigate = useNavigate();
  const dispatch = useDispatch();

  // for the verification funcs
  const [qrCodeData, setQrCodeData] = useState();
  const [onVerificationResult, setOnverificationResult] = useState(false);
  const [signUpQRData, setSignUpQRData] = useState();
  const [serverErrorFetch, setServerErrorFetch] = useState(false);

  const [sectionHide, setSectionHide] = useState(true);
  // show the Sign in content
  const [showSignIn, setShowSignIn] = useState(false);

  // smart contract actions data
  const fileStorageAddress = process.env.REACT_APP_BLOCK_CHAIN_CONTRACT_ADDRESS; // contract address
  const ChainId = process.env.REACT_APP_BLOCK_CHAIN_ID
  const fileStorageJSON = require("../../json/FileStorage.json");
  const fileStorageABI = fileStorageJSON.abi;
  const fileStorageInterface = new ethers.utils.Interface(fileStorageABI);

  // cehck auth validation did check
  const [authWalletAddress, setAuthWalletAddress] = useState(null)  // check already registered 
  const [toggleAuthWarning, setToggleAuthWarning] = useState(true)  // check already registered 

  const checkDidValidation = async () => {
    const provider = new ethers.providers.Web3Provider(window.ethereum)
    const signer = provider.getSigner()
    const contract = new ethers.Contract(fileStorageAddress, fileStorageABI, signer)
    const wallet_address = await signer.getAddress()

    const res = await contract.getAdressList(did)
    setLinkWallets(res)

    const isUserHere = res.includes(wallet_address)
    if (isUserHere === true) {
      setLinkwalletstatus(true)
      QRRtoggle2()
      setUserStatus((prev) => ({ ...prev, state: false, state1: false, state2: false, state3: false, state4: false, state5: false, state6: false, complete: false, msg: '', fail: false, failmsg: "" }))
    }
    return isUserHere

  }

  const auth = async () => {
    try {
      const authRequest = await fetch(
        process.env.REACT_APP_BACKEND_URL + "sign-in"
      );
      setQrCodeData(await authRequest.json());
      const sessionID = authRequest.headers.get("x-id");

      dispatch(WalletAddressSlice.actions.saveWalletAddress(sessionID));

      const interval = setInterval(async () => {
        try {
          const sessionResponse = await fetch(
            process.env.REACT_APP_BACKEND_URL + "status?sessionId=" + sessionID
          );

          if (sessionResponse.status === 200) {

            const did = await sessionResponse.json();
            dispatch(didSlice.actions.didStore(did.id));

            // add login points fn 
            addLoginPoints(did)

            setTimeout(() => {
              Navigate("/dashboard");
            }, 1000);
            clearInterval(interval);
          }

          if (sessionResponse.rejected) {
          }
          if (sessionResponse.status === 102) {
          }
        } catch (e) {
          console.log("err->", e);
        }
      }, 3000);
    } catch (error) {
      setServerErrorFetch(true);
    }
  };

  // if ref here redirect to register menu
  useEffect(() => {
    if (path !== "") {
      SignInQR()
    }
  }, []);

  // QR open and Close toggle
  const [QRtoggle, setQRtoggle] = useState(false);
  // const [QRtoggle2 , setQRtoggle2] = useState(false)
  const [error, setError] = useState("");
  const [toggleWarning, setToggleWarning] = useState(false) //for toggling user adding warning 

  const [did, setDid] = useState(null);

  const [metamaskWarning, setMetamaskWarning] = useState(false) //meta mask warning msg show and hide 

  // linked wallet addresses
  const [linkedWallets, setLinkWallets] = useState(null)
  const [linkwalletstatus, setLinkwalletstatus] = useState(false)
  const [walletaddressvalidation, setWalletaddressvalidation] = useState(false)

  const [userStatus, setUserStatus] = useState({ state: false, state1: false, state2: false, state3: false, state4: false, state5: false, state6: false, complete: false, msg: '', QRData: false, fail: false, failmsg: '' })


  // handle did submit function  ---->
  const handleDidSubmit = async (e) => {
    e.preventDefault();

    if (!did) {
      setError("Did is Required!");
      return;
    }
    setToggleWarning(false)

    setSubmitMsg("Loading");
    setError("");
    loadQRData() //load qr data fn 
    setDid("");

  };

  // Sign up qr toggle
  const QRRtoggle2 = () => {
    showHelp()
    setSectionHide(true);
    setShowSignIn(false);
    setDid(null);
    setError("");
    setLoginBtnShow(true);
    setRegisterBtnShow(true);
    setDisableRegisterBtn(true);
    setShowSubmitRegBtn(false);
    setShowRegenDiv(false)
    setRegen(false)
  };

  // close meta mask warning msg
  const closeMetaMaskWarning = () => {
    setMetamaskWarning(false)
  }


  // check wallet address is have a did 
  const checkWalletAddress = async () => {
    const provider = new ethers.providers.Web3Provider(window.ethereum)
    const signer = provider.getSigner()
    const contract = new ethers.Contract(fileStorageAddress, fileStorageABI, signer)

    const res = await contract.getUserDid()
    setWalletaddressvalidation(res)

    return res

  }


  // add user process
  // add user fn for block chanin
  const addUser = async () => {

    //check did is already registers 
    const userCheck = await checkDidValidation()

    const walletCheck = await checkWalletAddress()

    if (walletCheck === true) {
      QRRtoggle2()
      setUserStatus((prev) => ({ ...prev, state: false, state1: false, state2: false, state3: false, state4: false, state5: false, state6: false, complete: false, msg: '', fail: false, failmsg: "" }))
      return
    }

    if (userCheck === true) {
      return;
    }

    // check is QR data is loaded or not
    const isQRloaded = await loadQRData()

    if (isQRloaded === false) {
      setUserStatus((prev) => ({ ...prev, state: false, state1: false, state2: false, state3: false, state4: false, state5: false, state6: false, complete: false, msg: '', fail: true, failmsg: "QR loading Fail please try again" }))
      QRRtoggle2()
      setTimeout(() => {
        setUserStatus((prev) => ({ fail: false, failmsg: '' }))
      }, 3000);//if fails the actions
      return
    }

    const claim = isQRloaded.claim

    setUserStatus((prev) => ({ ...prev, state: true, state1: true, state2: false, state3: false, state4: false, state5: false, state6: false, complete: false, msg: '', fail: false, failmsg: "User adding in progress ..." }))


    const provider = new ethers.providers.Web3Provider(window.ethereum)
    const accounts = await provider.send('eth_requestAccounts', []);

    const acc = accounts[0]


    const secret = ethers.BigNumber.from(
      ethers.utils.randomBytes(32)
    ).toString();
    const nullifier = ethers.BigNumber.from(
      ethers.utils.randomBytes(32)
    ).toString();

    const input = {
      secret: $u.BN256ToBin(secret).split(""),
      nullifier: $u.BN256ToBin(nullifier).split(""),
    };
    const jsonContent = JSON.stringify(input, null, 4);
    var res = await fetch("/addUser.wasm");
    var buffer = await res.arrayBuffer();
    var adduserWC = await wc(buffer);

    const r = await adduserWC.calculateWitness(input, 0);

    const commitment = r[1];
    const nullifierHash = r[2];
    const value = ethers.BigNumber.from("100000000000000000").toHexString();

    const resAddUser = await fetch(process.env.REACT_APP_BACKEND_URL + "add-user", {
      method: "POST",
      body: JSON.stringify({
        "Commitment": commitment.toString(),
        "Did": did,
        "NullifierHash": nullifierHash.toString(),
        "Owner": acc
      })
    })

    setUserStatus((prev) => ({ ...prev, state: true, state1: true, state2: true, state3: false, state4: false, state5: false, state6: false, complete: false, msg: '', fail: false, failmsg: "User adding in progress ..." }))


    if (resAddUser.status === 200) {
      const add_user_data = await resAddUser.json()
      try {
        const proofElements = {
          nullifierHash: `${nullifierHash}`,
          secret: secret,
          nullifier: nullifier,
          commitment: `${commitment}`,
          txHash: add_user_data.TXHash,
        };
        const proof_element = btoa(JSON.stringify(proofElements));


        setUserStatus((prev) => ({ ...prev, state: true, state1: true, state2: true, state3: true, state4: false, state5: false, state6: false, complete: false, msg: '', fail: false, failmsg: "User adding in progress ..." }))
        // run verification process function
        verifyUser(proof_element, acc, provider, claim)



      } catch (e) {
        console.log(e);
        setUserStatus((prev) => ({ ...prev, state: false, state1: false, state2: false, state3: false, state4: false, state5: false, state6: false, complete: false, msg: '' })) //if fails the actions
        QRRtoggle2()
        setUserStatus((prev) => ({ ...prev, fail: true, failmsg: "User Adding was failed. Please try again" }))
        setTimeout(() => {
          setUserStatus((prev) => ({ ...prev, fail: false, failmsg: "" }))
        }, 3000);

      }
    } else {
      setUserStatus((prev) => ({ ...prev, state: false, state1: false, state2: false, state3: false, state4: false, state5: false, state6: false, complete: false, msg: '' })) //if fails the actions
      QRRtoggle2()
      setUserStatus((prev) => ({ ...prev, fail: true, failmsg: "User Adding was failed. Please try again" }))
      setTimeout(() => {
        setUserStatus((prev) => ({ ...prev, fail: false, failmsg: "" }))
      }, 3000);
    }

  };

  // state for time out 
  const [timeLeft, setTimeLeft] = useState(120)

  // set time out function for timout QR
  const timeCountDown = () => {
    const interval = setInterval(() => {
      setTimeLeft(prevTimeLeft => {
        if (prevTimeLeft === 1) {
          clearInterval(interval);
          setTimeLeft(120)
          QRRtoggle2();
        }
        return prevTimeLeft - 1;
      });
    }, 1000);
  };


  // set did registerd value
  const [registered, setRegistered] = useState(false)

  // get path
  const refPath = useSelector((state) => state.refLinkReducer)

  // load qr data into sign up QR
  const loadQRData = async () => {
    try {
      const QRdata = await fetch(process.env.REACT_APP_BACKEND_URL + `sign-up?refID=${refPath && refPath.RefLink}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          "DID": did,
          "First": "",
          "Last": "",
          "Email": "",

        }),
      });

      if (QRdata.status === 201) {
        setSectionHide(false);
        const claim = await QRdata.json()
        setSignUpQRData(claim.claim);
        timeCountDown()
        setSubmitMsg("Submit");

      }

      if (QRdata.status === 200) {
        setSectionHide(false);
        setRegistered(true)

        // regenaration msg
        setRegen(true)

        setTimeout(() => {
          setRegistered(false)
        }, 5000);
        setSubmitMsg("Submit");



      }
      // setIsLoadingCreateID(false)
    } catch (error) {
      console.log(error);
      setSectionHide(false);
      setSubmitMsg("Error");
    }
  }

  // verify user
  const verifyUser = async (proof_element, acc, provider, claim) => {
    try {
      setUserStatus((prev) => ({ ...prev, state1: true, state2: true, state3: true, state4: true, msg: "User Verifying in progress" }))

      const proofElements = JSON.parse(atob(proof_element));

      const receipt = await window.ethereum.request({
        method: "eth_getTransactionReceipt",
        params: [proofElements.txHash],
      });
      if (!receipt) {
        throw "empty-receipt";
      }

      const log = receipt.logs[1];

      const decodedData = await fileStorageInterface.decodeEventLog(
        "UserCollected",
        log.data,
        log.topics
      );

      const SnarkJS = window["snarkjs"];

      const proofInput = {
        root: $u.BNToDecimal(decodedData.useroot),
        nullifierHash: proofElements.nullifierHash,
        recipient: $u.BNToDecimal(acc),
        secret: $u.BN256ToBin(proofElements.secret).split(""),
        nullifier: $u.BN256ToBin(proofElements.nullifier).split(""),
        hashPairings: decodedData.userHashPairings.map((n) =>
          $u.BNToDecimal(n)
        ),
        hashDirections: decodedData.userPairDirection,
      };

      const { proof, publicSignals } = await snarkjs.groth16.fullProve(
        proofInput,
        "verifyUser.wasm",
        "setup_final.zkey"
      );

      const providers = new ethers.providers.Web3Provider(window.ethereum)
      const accounts = await providers.send('eth_requestAccounts', []);

      const callInputs = [
        proof.pi_a.slice(0, 2).map($u.BN256ToHex),
        proof.pi_b
          .slice(0, 2)
          .map((row) => $u.reverseCoordinate(row.map($u.BN256ToHex))),
        proof.pi_c.slice(0, 2).map($u.BN256ToHex),
        publicSignals.slice(0, 2).map($u.BN256ToHex),
        did
      ];

      const resUserVerify = await fetch(process.env.REACT_APP_BACKEND_URL + "verify-user", {
        method: "POST",
        body: JSON.stringify(
          {
            "A": JSON.stringify(proof.pi_a.slice(0, 2).map($u.BN256ToHex)),
            "B": JSON.stringify(proof.pi_b
              .slice(0, 2)
              .map((row) => $u.reverseCoordinate(row.map($u.BN256ToHex)))),
            "C": JSON.stringify(proof.pi_c.slice(0, 2).map($u.BN256ToHex)),
            "Inputs": JSON.stringify(publicSignals.slice(0, 2).map($u.BN256ToHex)),
            "Did": did,
            "Owner": accounts[0]
          }
        )
      })

      setUserStatus((prev) => ({ ...prev, state1: true, state2: true, state3: true, state4: true, state5: true, msg: "User Verifying in progress" }))


      if (resUserVerify.status === 200) {
        const tx_hash = await resUserVerify.json()

        provider
          .waitForTransaction(tx_hash.TXHash)
          .then((_receipt) => {
            if (_receipt.status === 0) {
              console.log("Transaction failed.");
              QRRtoggle2()
              setUserStatus((prev) => ({ ...prev, fail: true, failmsg: "Verify user was failed. Please try again" }))
              setTimeout(() => {
                setUserStatus((prev) => ({ ...prev, fail: false, failmsg: "" }))
              }, 3000);
            } else {
              console.log("Transaction successful. yeahhh");
              setSignUpQRData(claim)
              setUserStatus((prev) => ({ ...prev, state1: true, state2: true, state3: true, state6: true, complete: true, msg: "User Added Complete" }))


              setTimeout(() => {
                setUserStatus((prev) => ({ ...prev, state: false, state1: false, state2: false, state3: false, state4: false, state5: false, state6: false, complete: false, msg: '' })) //if fails the actions 

              }, 3000);
            }
          })
      }
      else {
        console.log("ts is fail ")
        QRRtoggle2()
        setUserStatus((prev) => ({ ...prev, fail: true, failmsg: "User Adding was failed. Please try again" }))
        setTimeout(() => {
          setUserStatus((prev) => ({ ...prev, fail: false, failmsg: "" }))
        }, 3000);
        setUserStatus((prev) => ({ ...prev, state: false, state1: false, state2: false, state3: false, state4: false, state5: false, state6: false, complete: false, msg: '' })) //if fails the actions
      }

      //   });
    } catch (error) {
      QRRtoggle2()
      setUserStatus((prev) => ({ ...prev, fail: true, failmsg: "User Adding was failed. Please try again" }))
      setTimeout(() => {
        setUserStatus((prev) => ({ ...prev, fail: false, failmsg: "" }))
      }, 3000);
      console.log("Errorddd:", error);
      setUserStatus((prev) => ({ ...prev, state: false, state1: false, state2: false, state3: false, state4: false, state5: false, state6: false, complete: false, msg: '' })) //if fails the actions
    }
  };

  const SignInQR = async () => {

    setDisableRegisterBtn(false);
    setLoginBtnShow(false);
    setQRtoggle(false);
    setToggleLoginInstruction(false);

    if (signUpQRData) {
      setSignUpQRData(null);
      setShowSignIn(true);
      return;
    }
    setShowSignIn(true);
    setShowHelpSec(true);
  };

  const logInQR = async () => {

    auth();
    const credential = localStorage.getItem("credentail");
    const authCredential = JSON.parse(credential);

    setTimeLeft(1000000)

    //log in fn for extention bethel
    if (authCredential) {
      const authRequest = await fetch(
        process.env.REACT_APP_BACKEND_URL + "sign-in"
      );
      const authData = await authRequest.json();

      const loginAuthHash =
        authData.body.scope[0].query.credentialSubject.auth.$eq;

      if (loginAuthHash === authCredential.auth) {
        Navigate("/dashboard");
      }
    }

    setRegisterBtnShow(false);
    setQRtoggle(true);
    setShowSignIn(false);
    setLoginBtnShow(false);
    setToggleLoginInstruction(true);
    setShowHelpSec(false);
  };

  const closeLoginQR = () => {
    setRegisterBtnShow(true);
    setSectionHide(true);
    setQRtoggle(false);
    setToggleLoginInstruction(false);
    setLoginBtnShow(true);
    setShowSubmitRegBtn(false)
  };


  const handlePatse = (e) => {
    const clipboardData = e.clipboardData || window.clipboardData;
    const pastedText = clipboardData.getData("text");
    setPasteDidShow(false);
    setShowHelpSec(false);

    // Here we'll need to simulate a change event for the input manually
    const updatedValue = pastedText;
    setDid(updatedValue);

    if (updatedValue.length === 69) {
      setShowSubmitRegBtn(true);
    } else {
      setShowSubmitRegBtn(false);
    }
  };

  const handlePatse2 = (e) => {
    const clipboardData = e.clipboardData || window.clipboardData;
    const pastedText = clipboardData.getData("text");

    // Here we'll need to simulate a change event for the input manually
    const updatedValue = pastedText;
    setRegenDid(updatedValue);
  };

  // add login points into user
  const addLoginPoints = async (did) => {
    try {
      const res = await fetch(process.env.REACT_APP_BACKEND_URL + "login-points", {
        method: "POST",
        body: JSON.stringify({
          "DID": did.id
        })
      })
      console.log(res)
    } catch (error) {
      console.log(error)
    }
  }

  // toggle values states
  const [toggleAndroid, setToggleAndroid] = useState(false);
  const [toggleIos, setToggleIos] = useState(false);

  // login and sign up button change
  const [loginBtnShow, setLoginBtnShow] = useState(true);
  const [registerBtnShow, setRegisterBtnShow] = useState(true);
  const [disableRegisterbtn, setDisableRegisterBtn] = useState(true);
  const [showSubmitRegBtn, setShowSubmitRegBtn] = useState(false);
  const [submitMsg, setSubmitMsg] = useState("Submit");

  // change register btn fn
  const changeRegisterBtnFn = () => {
    setDisableRegisterBtn(false);
  };

  //toggle login instrucutions
  const [toggleLoginInstruction, setToggleLoginInstruction] = useState(false);

  // steps
  const [deviceSelected, setDeviceSelected] = useState(false);
  const [stepDevice, setStepDevice] = useState(true);
  const [step1, setStep1] = useState(false);
  const [step1_1, setStep1_1] = useState(false);
  const [step2, setStep2] = useState(false);
  const [step3, setStep3] = useState(false);
  const [step4, setStep4] = useState(false);
  const [step5, setStep5] = useState(false);
  const [step6, setStep6] = useState(false);
  const [step7, setStep7] = useState(false);
  const [step8, setStep8] = useState(false);
  const [step9, setStep9] = useState(false);
  const [step10, setStep10] = useState(false);
  const [step11, setStep11] = useState(false);
  const [step12, setStep12] = useState(false);
  const [step13, setStep13] = useState(false);
  const [step14, setStep14] = useState(false);

  const [pasteDidShow, setPasteDidShow] = useState(false);
  const [logstep0, setLogStep0] = useState(true);
  const [logstep1, setLogStep1] = useState(false);
  const [logstep2, setLogStep2] = useState(false);
  const [logstep3, setLogStep3] = useState(false);
  const [logStep4, setLogStep4] = useState(false);

  // if apple selected
  const [appStoreLink, setAppStoreLink] = useState(false)

  // detect ios device
  const [isoDevice, setiOsDevice] = useState(false);

  // help section login
  const [showHelpSec, setShowHelpSec] = useState(false);

  // toggle ios and andoid fn
  const toggleIosfn = () => {
    setToggleAndroid(false);
    setToggleIos(toggleIos ? false : true);
  };

  const toggleAndoidfn = () => {
    setToggleAndroid(toggleAndroid ? false : true);
    setToggleIos(false);
  };

  const step8prev = () => {
    setStep1(true);
    setStep2(false);
    setStep3(false);
    setStep4(false);
    setStep5(false);
    setStep6(false);
    setStep7(false);
    setStep8(false);
  };

  const step8Next = () => {
    setStep1(false);
    setStep2(false);
    setStep3(false);
    setStep4(false);
    setStep5(false);
    setStep6(false);
    setStep7(false);
    setStep8(false);
    setStep9(true);
    setStep10(false);
  };

  const step1prevfn = () => {
    setStepDevice(true);
    setStep2(false);
    setStep1(false);
    setStep3(false);
    setStep7(false);
    setAppStoreLink(false)
  };

  const step1prev = () => {
    setStep1(false);
    setStep2(false);
    setStep3(false);
    setStep4(false);
    setStep1_1(true);
  };

  const step11prev = () => {
    setStepDevice(false);
    setStep2(false);
    setStep1(true);
    setStep3(false);
    setStep1_1(false);
  };

  const step3prev = () => {
    setStep1(false);
    setStep2(false);
    setStep3(false);
    setStep4(true);
    setStep1_1(false);
  };

  const step2fn = () => {
    setStep1(false);
    setStep2(true);
    setStep3(false);
    setStep4(false);
  };

  const step4prev = () => {
    setStep1(false);
    setStep2(true);
    setStep3(false);
    setStep4(false);
  };

  const step1Next = () => {
    setStep1(false);
    setStep2(false);
    setStep3(false);
    setStep4(false);
    setStep5(false);
    setStep6(false);
    setStep7(false);
    setStep8(true);
    setStep9(false);
  };

  const step3fn = () => {
    setStep1(false);
    setStep2(false);
    setStep4(false);
    setStep3(false);
    setStep5(false);
    setStep1_1(true);
  };

  const nextStep11 = () => {
    setStep1(false);
    setStep2(false);
    setStep4(true);
    setStep3(false);
    setStep5(false);
    setStep1_1(false);
  };

  const step4fn = () => {
    setStep1(false);
    setStep2(false);
    setStep4(false);
    setStep3(true);
    setStep6(false);
    setStep5(false);
    setPasteDidShow(false);
  };

  const step5fn = () => {
    setStep1(false);
    setStep2(false);
    setStep4(false);
    setStep3(false);
    setStep5(true);
    setStep6(false);
    setStep7(false);
    setPasteDidShow(false);
  };

  const step6fn = () => {
    setStep1(false);
    setStep2(false);
    setStep4(false);
    setStep3(false);
    setStep5(false);
    setStep6(true);
    setStep7(false);
    setPasteDidShow(true);
  };

  const step7fn = () => {
    setStep1(false);
    setStep2(false);
    setStep4(false);
    setStep3(false);
    setStep5(false);
    setStep6(false);
    setStep7(true);
  };

  const step10Next = () => {
    setStep1(false);
    setStep2(false);
    setStep3(false);
    setStep4(false);
    setStep5(false);
    setStep6(false);
    setStep7(false);
    setStep8(false);
    setStep9(false);
    setStep10(false);
    setStep11(true);
    setStep12(false);
  };

  const logstep1fn = () => {
    setLogStep1(false);
    setLogStep2(true);
    setLogStep3(false);
    setLogStep4(false);
  };

  const logstep1fnprev = () => {
    setLogStep1(true);
    setLogStep2(false);
  };

  const logStep3fn = () => {
    setLogStep1(false);
    setLogStep2(false);
    setLogStep3(true);
    setLogStep4(false);
  };

  const selectDeviceStep = () => {
    setStep2(false);
    setStep1(true);
    setStep3(false);
    setStep9(false);
    setStepDevice(false);
    setDeviceSelected(true);
    setAppStoreLink(true)
  };

  const selectDeviceStepAndroid = () => {
    setStep2(false);
    setStep1(true);
    setStep3(false);
    setStepDevice(false);
    setDeviceSelected(false);
  };

  const showHelp = () => {
    if (toggleLoginInstruction) {
      setShowSubmitRegBtn(false)

    }
    setShowHelpSec(!showHelpSec);
    setHideHelpSec(!hideHelpSec);


    // setShowSignIn(true);
    // setDisableRegisterBtn(false);
    // setLoginBtnShow(false);
    // setIsSubmitingDid(false);


    // setQRtoggle(false);

    if (signUpQRData) {
      setSignUpQRData(null);
      setShowSignIn(true);
      return;
    }

    // setShowHelpSec(true)
  };

  const nextStep13 = () => {
    setShowHelpSec(!showHelpSec);
    setHideHelpSec(!hideHelpSec);
    setiOsDevice(false);

    setDisableRegisterBtn(false);
    setLoginBtnShow(false);
    setQRtoggle(false);

    if (signUpQRData) {
      setSignUpQRData(null);
      setShowSignIn(true);
      return;
    }
    setShowSignIn(true);
    setShowHelpSec(true);
    setPasteDidShow(true)
  };

  const step9Next = () => {
    setStep1(false);
    setStep2(false);
    setStep4(false);
    setStep3(false);
    setStep5(false);
    setStep6(false);
    setStep7(false);
    setStep8(false);
    setStep9(false);
    setStep10(true);
    setStep11(false);
    setStep13(false);
  };

  const step11Next = () => {
    setStep1(false);
    setStep2(false);
    setStep3(false);
    setStep4(false);
    setStep5(false);
    setStep6(false);
    setStep7(false);
    setStep8(false);
    setStep9(false);
    setStep10(false);
    setStep11(false);
    setStep12(true);
    setStep13(false);
  };

  const step12Next = () => {
    setStep1(false);
    setStep2(false);
    setStep3(false);
    setStep4(false);
    setStep5(false);
    setStep6(false);
    setStep7(false);
    setStep8(false);
    setStep9(false);
    setStep10(false);
    setStep11(false);
    setStep12(false);
    setStep13(true);
    setStep14(false);
  };

  const step13Next = () => {
    setStep1(false);
    setStep2(false);
    setStep3(false);
    setStep4(false);
    setStep5(false);
    setStep6(false);
    setStep7(false);
    setStep8(false);
    setStep9(false);
    setStep10(false);
    setStep11(false);
    setStep12(false);
    setStep13(false);
    setStep14(true);
    setStep1_1(false);
  };

  const step14Next = () => {
    setStep1(false);
    setStep2(false);
    setStep3(false);
    setStep4(false);
    setStep5(false);
    setStep6(false);
    setStep7(false);
    setStep8(false);
    setStep9(false);
    setStep10(false);
    setStep11(false);
    setStep12(false);
    setStep13(false);
    setStep14(false);
    setStep1_1(true);
  };

  const nextStep4 = () => {
    setLogStep1(false);
    setLogStep2(false);
    setLogStep3(false);
    setLogStep4(true);
  };
  const logstep1prevfn = () => {
    setLogStep1(false);
    setLogStep0(true);
    setLogStep2(false);
  };

  const logStep0next = () => {
    setLogStep1(true);
    setLogStep2(false);
    setLogStep0(false);
  };

  const logStep5Finish = () => {
    QRRtoggle2();
    setShowHelpSec(false);
    setHideHelpSec(false);
    setToggleLoginInstruction(false);
  };

  const mainMenu = () => {
    setHideHelpSec(true);
    QRRtoggle2();
    setStep1(false);
  };


  useEffect(() => {
    if (iOS()) {
      setiOsDevice(true);
    }
  }, []);



  function iOS() {
    return (
      ["iPad"].includes(navigator.platform) ||
      // iPad on iOS 13 detection
      (navigator.userAgent.includes("Mac") && "ontouchend" in document)
    );
  }

  const [hideHelpSec, setHideHelpSec] = useState(false);


  const [regen, setRegen] = useState(false)
  const [regenDid, setRegenDid] = useState()
  const [showRegenDiv, setShowRegenDiv] = useState(false)
  const [regenMsg, setRegenMsg] = useState("Submit")

  // re genarate calim 
  const regenarate_claim_submit = async () => {
    console.log(regenDid)
    setRegenMsg("Loading")
    try {
      const res = await fetch(process.env.REACT_APP_BACKEND_URL + `regenerate-reg-claim`, {
        method: "POST",
        body: JSON.stringify({
          "DID": regenDid
        })
      })

      console.log(res)
      if (res.status === 201) {
        const regenQR = await res.json()
        setSectionHide(false);
        const claim = await regenQR
        // show claim
        console.log(claim)
        setSignUpQRData(claim.claim);
        timeCountDown()

        setRegenMsg("Submit")

        setRegen(false)
        setShowRegenDiv(false)
      }
      else {
        setRegenMsg("Error")

      }
    } catch (error) {
      console.log(error)
      setRegenMsg("Error")

    }

  }

  //toggle regen did
  const regenareate_claim = () => {
    setShowRegenDiv(true)
    setRegen(false)
  }

  const [newDid, setNewDid] = useState()
  const [new_feature_claim, setNew_fetaure_calim] = useState(null)
  const [toggle_new_feature_qr, setToggle_new_feature_qr] = useState(false)
  const [toggle_main_div, setToggle_main_div] = useState(false)
  const [errorMsg, setErrorMsg] = useState(false)

  // set emergency qr data 
  const new_feature_qr = async () => {
    console.log(newDid)
    try {
      const res = await fetch(process.env.REACT_APP_BACKEND_URL + `regenerate-reg-claim`, {
        method: "POST",
        body: JSON.stringify({
          "DID": newDid
        })
      })

      console.log(res)
      if (res.status === 201) {
        setToggle_new_feature_qr(true)
        const regenQR = await res.json()
        setSectionHide(false);
        const claim = await regenQR
        // show claim
        console.log(claim)
        setNew_fetaure_calim(claim.claim);

      }
      else {
        setErrorMsg(true)

        setTimeout(() => {
          setErrorMsg(false)
        }, 3000);

      }
    } catch (error) {
      console.log(error)
      setRegenMsg("Error")
      setErrorMsg(true)

        setTimeout(() => {
          setErrorMsg(false)
        }, 3000);

    }
  }

  // close qr 
  const close_new_feature_Qr = () => {
    setToggle_new_feature_qr(false)
    setToggle_main_div(false)
    logInQR()
  }

  const main_div_close = () => {
    setToggle_main_div(false)
    logInQR()
  }

  return (
    <section className="w-full h-screen bg-center min-[1024px]:bg-black min-[1100px]:bg-transparent md:bg-transparent sm:bg-black min-[320px]:bg-black">

      {/* maintaince annoucement */}
      <div className='w-full'>
       
        <div className="fixed z-[99999] left-2 bottom-[100px] w-[500px] inline-block text-sm text-white border border-gray-200 rounded-lg shadow-sm bg-[#0F0F0F] animate__animated animate__fadeInLeft animate__fast">

          <div className="px-3 py-2 bg-bethel-green/60 border-b border-gray-200 rounded-t-lg flex gap-2 items-center">
            <div className="p-2 rounded-full bg-black">
              <img src={behtelIcon} className="w-[30px]" alt="" />
            </div>
            <h3 className="font-semibold text-[20px] text-white">Scheduled Maintenance</h3>
          </div>

         
          <div className="px-3 py-2 flex flex-col gap-3">
            <ul className="list-disc pl-5 text-[12px]">
              <li>We're gearing up for an upgrade to provide you for better experience.</li>
              <li className="">Some services will be unavailable on,</li>
              {/* <li>Some services will be unavailable on,<span className="text-bethel-newgreen font-bold"> 9th December at 07:30 EST / 22:30 AEST / 18:00 IST <span className="text-white">until</span>  09:30 EST / 00:30 AEST / 20:00 IST.</span></li> */}
            </ul>
          </div>

          
          <div className="absolute -bottom-1 left-20 transform translate-y-full w-6 h-6 border-t-[30px] border-t-[#0F0F0F] border-r-[30px] border-r-transparent"></div>
        </div>

       
        <div className='fixed -left-12 z-[999999] -bottom-[60px] -rotate-12 rounded-full p-5 animate__animated animate__fadeInLeft animate__fast'>
          <img src={robot} className='w-[100px] opacity-90 transform scale-x-[-1] animate-pulse' alt="" />
        </div>

      </div>
      {/* end of main tainre  */}

      {/* for mobile devices  */}
      {/* <div className={`${isoDevice ? "hidden" : "lg:hidden md:hidden sm:hidden min-[320px]:hidden"} bg-black w-full h-full fixed z-[99999] justify-center flex-col items-center md:px-20 sm:px-20 min-[320px]:px-10`}>

        <div className="w-full flex  justify-center">
          <h3 className="font-bold text-bethel-green text-[20px]">Mainnet.bethel.network</h3>
        </div>

        <hr className="text-white bg-white mt-2 w-[90%] opacity-30 mb-2" />

        <div className="flex w-full justify-center animate-pulse">
          <img src={BethelImg} className="w-[150px]" alt="" />
        </div>

        <div className="text-white font-semibold min-[320px]:text-[15px] md:text-[25px] sm:text-[25px] w-full justify-center items-center flex text-center flex-col py-4">
          <Typewriter className="text-center w-[100%] text-red-500"
            options={{
              strings: ["Some parts of Bethel zkpStorage may not work on a mobile device. Apple and Android Support will be enabled shortly."],
              autoStart: true,
              cursor: "_",
              delay: 60,
              deleteSpeed: Infinity, // Lower value for faster typing speed
            }}
          />
        </div>

        <a href="https://bethelnet.io/" className="p-2 font-bold text-bethel-green border border-bethel-green rounded-lg mt-2">Take me to BETHELNET.IO</a>
      </div> */}

      <video
        className={` ${isoDevice ? "hidden object-center scale-[2.5] fixed" : "lg:flex md:hidden sm:hidden min-[320px]:flex object-cover absolute"}  w-full h-screen z-10`}
        src={heroVid}
        autoPlay
        loop
        muted
      />

      <div className={` ${isoDevice ? "flex object-center scale-[1] fixed" : "lg:hidden md:hidden sm:hidden min-[320px]:flex object-cover absolute"}  w-full h-screen z-10 bg-black`}>
        <img src={globeImg} alt="" className="w-full object-cover" />
      </div>


      {/* 3d object section */}
      <div className="absolute w-full h-screen bg-black z-[10000] hidden">
        <Canvas
          onCreated={({ camera, gl }) => {
            cameraRef.current = camera;
            gl.setClearColor('#000000'); // Set clear color to black for night sky
          }}
          camera={{ position: [0, 2, 9], fov: 55, near: 2 }}>
          {/* Ambient light for a subtle overall illumination */}
          <ambientLight intensity={0.1} />

          {/* Directional light to simulate moonlight */}
          <directionalLight
            position={[10, 10, 10]}
            intensity={0.5}
            color="#bbbbff" // Slightly blueish light to simulate moonlight
          />


          {pos && <OrbitControls autoRotate autoRotateSpeed={0.5} scale={2} />}
          {/* Stars for night sky */}
          <Stars
            radius={20}
            depth={10}
            count={5000}
            factor={4}
            saturation={10}
            fade
            brightness={10} // Increase brightness for stars
          />


          {/* 3D Model */}
          <Scene className="" />

          {/* Camera Animation */}
          {!pos && <CameraAnimation camera={cameraRef.current} onAnimationComplete={handleAnimationComplete} />}


        </Canvas>
      </div>


      <div className={`w-full pt-10 h-screen flex flex-col justify-center items-center text-white px-4 text-center ${userStatus.fail ? 'bg-red-500/20' : ''} `}>
        {/* QR section */}
        <div className="h-[600px]  rounded-lg p-2  flex justify-center items-center relative z-[50000]">
          {/* css shape  */}
          <div className="w-full absolute bottom-0">
            {/* <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320"><path fill="#a3d902" fill-opacity="0.2" d="M0,224L15,197.3C30,171,60,117,90,80C120,43,150,21,180,48C210,75,240,149,270,154.7C300,160,330,96,360,112C390,128,420,224,450,256C480,288,510,256,540,245.3C570,235,600,245,630,234.7C660,224,690,192,720,202.7C750,213,780,267,810,272C840,277,870,235,900,192C930,149,960,107,990,122.7C1020,139,1050,213,1080,250.7C1110,288,1140,288,1170,272C1200,256,1230,224,1260,208C1290,192,1320,192,1350,192C1380,192,1410,192,1425,192L1440,192L1440,320L1425,320C1410,320,1380,320,1350,320C1320,320,1290,320,1260,320C1230,320,1200,320,1170,320C1140,320,1110,320,1080,320C1050,320,1020,320,990,320C960,320,930,320,900,320C870,320,840,320,810,320C780,320,750,320,720,320C690,320,660,320,630,320C600,320,570,320,540,320C510,320,480,320,450,320C420,320,390,320,360,320C330,320,300,320,270,320C240,320,210,320,180,320C150,320,120,320,90,320C60,320,30,320,15,320L0,320Z"></path></svg> */}
          </div>
          {/* end of the css shape */}

          {/* bethel informaiton section */}
          <div
            className={`relative flex justify-center flex-col p-2 min-[1100px]:flex `}
          >
            <div className="relative">
              <h1 className="lg:text-[60px] md:text-[40px] sm:text-[60px] min-[320px]:text-[30px] font-bold bg-gradient-to-r from-bethel-orange to-bethel-newgreen text-transparent bg-clip-text outline-4 outline-white w-full flex gap-2 justify-center">
                Bethel
                <span className={`${userStatus.state ? 'animate-pulse' : 'text-white'}`}>
                  zkpStorage
                </span>


              </h1>
              <h3 className={` mb-2 text-white font-bold text-center pb-6 lg:w-[800px] md:w-[600px] sm:w-[600px] min-[320px]:w-full ${isoDevice ? "text-[18px]" : "lg:text-[20px] md:text-[20px] sm:text-[10px] min-[320px]:text-[10px]"}`}>
                Hack Proof Blockchain Based Secure Decentralised File Storage
                with (ZKP) Zero-Knowledge Proofs
              </h3>







              {/* special registration */}
              {toggle_main_div &&
                <div className="p-5  rounded-lg flex flex-col gap-4  items-center justify-center z-[99999] w-full border border-bethel-green/50">
                  {!toggle_new_feature_qr ?
                    <div className="w-full p-4 gap-2 flex flex-col relative ">
                      <div className="font-semibold text-[16px] text-white">
                        To enable upgraded advanced features on your account, please enter your DID below and scan QR
                      </div>

                      <div className="flex gap-2 justify-center">
                        <input onChange={(e) => setNewDid(e.target.value)} type="text" className="outline-none p-2 text-white w-[60%] bg-transparent border-white/40 border rounded-xl " />
                        <button className="border border-bethel-green hover:bg-bethel-green/50 py-1 px-3 rounded-lg text-[14px] font-bold " onClick={new_feature_qr}>{errorMsg ? "Error " : "Submit"}</button>
                      </div>


                      <button onClick={main_div_close} className="absolute -top-3 -right-3 p-1 rounded-full border border-red-500 hover:bg-red-100">
                        <img className="w-[20px]" src={iconclose} alt="" />
                      </button>
                    </div>
                    :
                    <div className="relative w-[280px] rounded-xl bg-white p-4 flex flex-col items-center justify-center animate__animated animate__fadeIn">
                      <h3 className="text-black mb-5 font-bold text-[15px]">
                        BETHEL zkpSTORAGE{" "}
                      </h3>
                      <img
                        src={loaderSearch}
                        alt=""
                        className="w-[50px] absolute top-5 right-34"
                      />
                      <QRCode
                        value={JSON.stringify(new_feature_claim)}
                        className={`flex w-48 h-48 p-1 bg-white top-0`}
                      />

                      <div className="flex w-full justify-center mt-2">
                        <h3 className="text-[14px] font-bold text-black">Scan this QR for add new features</h3>
                      </div>


                      <div className="absolute right-2 top-0 ">
                        <button onClick={close_new_feature_Qr}>
                          <h3 className="text-black text-xl">x</h3>
                        </button>
                      </div>

                    </div>
                  }

                </div>}








              {/* show sign in content */}
              {showSignIn && (
                <div className="flex gap-2 ">
                  {sectionHide && (
                    <div className="flex flex-col w-full justify-center items-center gap-2">
                      {/* input field */}
                      <div className="relative w-full">
                        <input
                          type="text"

                          className={`outline-none border-b-[2px] border-t-[2px]  ${pasteDidShow
                            ? "border-red-600"
                            : "border-bethel-green"
                            } bg-transparent lg:w-[400px] md:w-[400px] sm:w-full min-[320px]:w-full rounded-lg py-2 px-4 text-white'`}
                          placeholder={pasteDidShow ? "Paste your DID here" : "Please follow instrucitons"}

                          onPaste={handlePatse}
                        />
                        {error && (
                          <div className="my-2 pl-2 text-red-600 absolute left-[-190px] top-10  text-center font-bold text-[14px] pb-2 flex w-full justify-center">
                            {error}
                          </div>
                        )}

                        {/* paste did instructions  */}
                        {false && (
                          <div
                            className={`absolute flex lg:flex md:hidden sm:hidden items-center  justify-center w-[300px] top-1 left-[-200px]`}
                          >
                            <div className="w-full border-2 border-red-600 py-1 text-[18px]">
                              <h3>Paste your DID here</h3>
                            </div>
                            <div className=" text-red-600">
                              <span class="material-symbols-outlined">
                                trending_flat
                              </span>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              )}
            </div>

            <div className="w-full font-bold text-center justify-center items-center relative flex flex-col">



              {/* verification QR for */}
              {!onVerificationResult ? (
                <div className="flex w-full items-center justify-center gap-4 flex-col">

                  {/* sing up QR and Log in QR */}
                  <div className="flex gap-4 w-full justify-center">
                    {/* log in QR */}
                    <div>
                      {qrCodeData &&
                        (QRtoggle ? (
                          <div className="flex">
                            <div className="relative w-[280px] rounded-xl bg-white p-4 flex flex-col items-center justify-center animate__animated animate__fadeIn">
                              <h3 className="text-black mb-3">
                                BETHEL zkpSTORAGE{" "}
                              </h3>
                              <img
                                src={loaderSearch}
                                alt=""
                                className="w-[50px] absolute top-5 right-34"
                              />
                              <QRCode
                                value={JSON.stringify(qrCodeData)}
                                className={`flex w-48 h-48 p-1 bg-white top-0`}
                              />
                              <div className="flex w-full flex-col items-start pl-4 relative">
                                <h3 className="text-black mb-1 text-[12px] mt-1 w-full text-start">
                                  1. Scan this QR code from your{" "}
                                  <span className="text-purple-600">
                                    Polygon ID
                                  </span>{" "}
                                  Mobile App
                                </h3>
                                <h3 className="text-black mb-1 text-[12px] mt-1">
                                  2. Select{" "}
                                  <span className="text-purple-600">
                                    Public
                                  </span>{" "}
                                  Profile
                                </h3>
                                <h3 className="text-black mb-1 text-[12px] mt-1">
                                  3. Press{" "}
                                  <span className="text-purple-600">
                                    Approve
                                  </span>{" "}
                                </h3>
                              </div>

                              <div className="absolute -bottom-[60px] text-[14px] w-[500px] p-1 border border-red-500 rounded-lg">
                                <h3>If you encoutering this error <span className="text-red-500">"Instance of "CredentialsNotFoundException' Error :true"</span> please add new feature</h3>
                              </div>


                              {/* little x */}
                              <div className="absolute right-2 top-0 ">
                                <button onClick={closeLoginQR}>
                                  <h3 className="text-black text-xl">x</h3>
                                </button>
                              </div>
                              {/* close little x */}
                            </div>
                          </div>
                        ) : (
                          <div></div>
                        ))}
                    </div>

                    {/* sign up QR */}
                    <div>
                      {(showSignIn ? (
                        <div className="flex relative">

                          {/* error msg if user is already added  */}
                          {registered &&
                            <div className="text-[14px] text-white w-[500px] border border-red-500 bg-black p-2 my-2 absolute top-32 rounded-xl left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-[10]">
                              <h3 className="text-red-500">This DID is already Registered. <br /> Please Go to Login or Regenarate claim!</h3>
                            </div>}

                          {/* end of error msg */}

                          {/* regenarate claim button */}
                          {regen &&
                            <button onClick={regenareate_claim} className="text-[14px] text-white hover:bg-bethel-green hover:text-black w-[250px] border border-bethel-green p-2 my-2 absolute top-32 rounded-xl left-1/4 transform -translate-x-1/2 -translate-y-1/2 z-[0]">
                              <h3>Re-genarate Claim</h3>
                            </button>
                          }
                          {/* end of regenarate calim button */}

                          {/* input regen box */}
                          {showRegenDiv &&
                            <div className="text-[14px] text-white  p-2 my-2 absolute top-36 rounded-xl left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-[0] flex justify-center items-center gap-2">
                              <input onPaste={handlePatse2} onChange={(e) => setRegenDid(e.target.value)} type="text" className="bg-white/10 flex w-[400px] border border-bethel-green p-3 text-white rounded-lg bg-transparent" placeholder="Enter your DID here" />
                              <button onClick={regenarate_claim_submit} className="flex px-3 py-3 border bg-white/10 border-bethel-green hover:bg-bethel-green rounded-lg ">{regenMsg}</button>
                            </div>
                          }


                          <div className="relative mr-8">
                            {signUpQRData ? (
                              <div className="relative w-[280px] rounded-xl bg-white p-4 flex flex-col items-center justify-center">
                                <h3 className="text-black mb-3">
                                  BETHEL zkpSTORAGE{" "}
                                </h3>
                                <img
                                  src={loaderSearch}
                                  alt=""
                                  className="w-[50px] absolute top-5 right-34"
                                />
                                <QRCode
                                  value={JSON.stringify(signUpQRData)}
                                  className={`flex w-48 h-48 p-1 bg-white top-0`}
                                />

                                <div className="absolute right-4 top-2 ">
                                  <button onClick={QRRtoggle2}>
                                    <h3 className="text-black text-xl">x</h3>
                                  </button>
                                </div>
                                <h3 className="text-red-500 mb-1 text-[12px] mt-1">
                                  After scanning, Close this QR and Click login <br />
                                  QR is Auto closing in :  {timeLeft}
                                </h3>

                              </div>
                            ) : (
                              <div></div>
                            )}
                          </div>
                        </div>
                      ) : (
                        <div></div>
                      ))}
                    </div>
                  </div>

                  {/* log in button and sign up button div */}
                  <div className={`flex lg:flex-row md:flex-row sm:flex-col min-[320px]:flex-col gap-3  ${isoDevice ? "scale-100" : "lg:scale-100 md:scale-100 sm:scale-50 min-[320px]:scale-75"}`}>
                    {/* log in button */}
                    {loginBtnShow && (
                      <div className={`${toggle_main_div ? "hidden" : ''}`}>
                        {/* button */}
                        <button
                          onClick={() => setToggle_main_div(true)}
                          className="flex items-center justify-center xs:flex-col xxs:flex-col xxxs:flex-col w-full "
                        >
                          <div className="flex duration-500 hover:-translate-y-1 delay-50 hover:text-white xs:mb -5 xxs:mb-6 xxxs:mb-5">
                            <div className="triangle-container bg-bethel-orange w-4 h-3 -mr-4 xxxs:w-[10px] xxxs:h-2 xxxs:-mr-[10px] rounded-md"></div>
                            <div className="relative w-56 h-12 mr-4 bg-gradient-to-r from-bethel-orange to-bethel-newgreen xxxs:w-36 xxxs:h-8 hover:text-white rounded-md">
                              <div className=" w-[220px] h-[44px] xxxs:w-[140px] xxxs:h-[28px] relative left-[2px] top-[2px] bg-black hover:bg-transparent hover:text-white rounded-md">
                                <span className="flex items-center justify-center font-bold text-white h-11 xxxs:h-7 hover:text-black font-raleway text-[16px]">
                                  Log In
                                </span>
                              </div>
                            </div>
                          </div>
                        </button>
                        {/* end of the button */}
                      </div>
                    )}

                    {/* sign up QR button */}
                    {!showSubmitRegBtn ? (
                      <div>
                        {registerBtnShow && (
                          <div>
                            {disableRegisterbtn ? (
                              <div className={`${toggle_main_div ? "hidden" : ''}`}>
                                <button
                                  onClick={SignInQR}
                                  className="flex items-center justify-center xs:flex-col xxs:flex-col xxxs:flex-col w-full "
                                >
                                  <div className="flex duration-500 hover:-translate-y-1 delay-50 hover:text-white xs:mb-5  xxs:mb-6 xxxs:mb-5">
                                    <div className="triangle-container bg-bethel-orange w-4 h-3 -mr-4 xxxs:w-[10px] xxxs:h-2 xxxs:-mr-[10px]  "></div>
                                    <div className="relative w-56 h-12 mr-4 bg-gradient-to-r from-bethel-orange to-bethel-newgreen xxxs:w-36 xxxs:h-8 hover:text-white rounded-md">
                                      <div className=" w-[220px] h-[44px] xxxs:w-[140px] xxxs:h-[28px] relative left-[2px] top-[2px] bg-black hover:bg-transparent hover:text-white rounded-md">
                                        <span className="flex items-center justify-center font-bold text-white h-11 xxxs:h-7 hover:text-black font-raleway text-[16px]">
                                          Register Now
                                        </span>
                                      </div>
                                    </div>
                                    {/* <div className="triangle-container1 bg-bethel-newgreen w-4 h-3 -ml-8 mt-9 xxxs:w-[10px] xxxs:h-2 xxxs:-ml-[26px] xxxs:mt-6 hover:bg-bethel-newgreen "></div> */}
                                  </div>
                                </button>
                              </div>
                            ) : (
                              <div className="w-full relative ml-7">
                                <button
                                  onClick={changeRegisterBtnFn}
                                  className="flex items-center justify-center xs:flex-col xxs:flex-col xxxs:flex-col w-full "
                                >
                                  <div className="flex duration-500  delay-50 xs:mb-5  xxs:mb-6 xxxs:mb-5">
                                    <div className="triangle-container bg-bethel-orange w-4 h-3 -mr-4 xxxs:w-[10px] xxxs:h-2 xxxs:-mr-[10px]  "></div>
                                    <div className="relative w-56 h-12 mr-4  bg-gray-300 xxxs:w-36 xxxs:h-8  rounded-md">
                                      <div className="  w-[220px] h-[44px] xxxs:w-[140px] xxxs:h-[28px] relative left-[2px] top-[2px] bg-gray-600  rounded-md">
                                        <span className="flex items-center justify-center font-bold text-white h-11 xxxs:h-7font-raleway text-[16px]">
                                          Submit
                                        </span>
                                      </div>
                                    </div>
                                    {/* <div className="triangle-container1 bg-bethel-newgreen w-4 h-3 -ml-8 mt-9 xxxs:w-[10px] xxxs:h-2 xxxs:-ml-[26px] xxxs:mt-6 hover:bg-bethel-newgreen "></div> */}
                                  </div>
                                </button>

                                {/* home button for the  */}
                                <button
                                  onClick={QRRtoggle2}
                                  className="absolute left-[-35px] top-[6px] border-[1px] scale-[1.4] bg-gray-500 flex items-center p-[6px] rounded-md"
                                >
                                  <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="20" height="20" viewBox="0,0,256,256"
                                  >
                                    <g fill="#ffffff" fill-rule="nonzero" stroke="none" stroke-width="1" stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0" font-family="none" font-weight="none" font-size="none" text-anchor="none"><g transform="scale(5.12,5.12)"><path d="M25,1.05078c-0.2175,0 -0.43414,0.06898 -0.61914,0.20898l-23,17.95117c-0.43,0.34 -0.50992,0.9682 -0.16992,1.4082c0.34,0.43 0.9682,0.50992 1.4082,0.16992l1.38086,-1.07812v26.28906c0,0.55 0.45,1 1,1h14v-18h12v18h14c0.55,0 1,-0.45 1,-1v-26.28906l1.38086,1.07812c0.19,0.14 0.39914,0.21094 0.61914,0.21094c0.3,0 0.58906,-0.13086 0.78906,-0.38086c0.34,-0.44 0.26008,-1.0682 -0.16992,-1.4082l-23,-17.95117c-0.185,-0.14 -0.40164,-0.20898 -0.61914,-0.20898zM35,5v1.05078l6,4.67969v-5.73047z"></path></g></g>
                                  </svg>
                                </button>
                                {/* end of the home button */}
                              </div>
                            )}
                          </div>
                        )}
                      </div>
                    ) : (
                      <div>
                        <div className="relative ml-7">
                          <button
                            onClick={handleDidSubmit}
                            className={`flex items-center justify-center xs:flex-col xxs:flex-col xxxs:flex-col w-full ${sectionHide ? "flex" : "hidden"}`}
                          >
                            <div className="flex duration-500 hover:-translate-y-1 delay-50 hover:text-white xs:mb-5  xxs:mb-6 xxxs:mb-5">
                              <div className="triangle-container bg-bethel-orange w-4 h-3 -mr-4 xxxs:w-[10px] xxxs:h-2 xxxs:-mr-[10px]  "></div>
                              <div className="relative w-56 h-12 mr-4  bg-gradient-to-r from-bethel-orange to-bethel-newgreen xxxs:w-36 xxxs:h-8 hover:text-white rounded-md">
                                <div className="  w-[220px] h-[44px] xxxs:w-[140px] xxxs:h-[28px] relative left-[2px] top-[2px] bg-black hover:bg-transparent hover:text-white rounded-md">
                                  <span className="flex items-center justify-center font-bold text-white h-11 xxxs:h-7 hover:text-black font-raleway text-[16px]">
                                    {submitMsg}
                                  </span>
                                </div>
                              </div>

                              {/* end of the home button */}
                              {/* <div className="triangle-container1 bg-bethel-newgreen w-4 h-3 -ml-8 mt-9 xxxs:w-[10px] xxxs:h-2 xxxs:-ml-[26px] xxxs:mt-6 hover:bg-bethel-newgreen "></div> */}
                            </div>
                          </button>

                          {/* home button for the  */}
                          <button
                            onClick={QRRtoggle2}
                            className="absolute left-[-50px] top-[6px] border-[1px] scale-[1.4] bg-gradient-to-r from-bethel-orange to-bethel-newgreen flex items-center p-[6px] rounded-md"
                          >
                            <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="20" height="20" viewBox="0,0,256,256"
                            >
                              <g fill="#ffffff" fill-rule="nonzero" stroke="none" stroke-width="1" stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0" font-family="none" font-weight="none" font-size="none" text-anchor="none"><g transform="scale(5.12,5.12)"><path d="M25,1.05078c-0.2175,0 -0.43414,0.06898 -0.61914,0.20898l-23,17.95117c-0.43,0.34 -0.50992,0.9682 -0.16992,1.4082c0.34,0.43 0.9682,0.50992 1.4082,0.16992l1.38086,-1.07812v26.28906c0,0.55 0.45,1 1,1h14v-18h12v18h14c0.55,0 1,-0.45 1,-1v-26.28906l1.38086,1.07812c0.19,0.14 0.39914,0.21094 0.61914,0.21094c0.3,0 0.58906,-0.13086 0.78906,-0.38086c0.34,-0.44 0.26008,-1.0682 -0.16992,-1.4082l-23,-17.95117c-0.185,-0.14 -0.40164,-0.20898 -0.61914,-0.20898zM35,5v1.05078l6,4.67969v-5.73047z"></path></g></g>
                            </svg>
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              ) : (
                <div></div>
              )}

              {/* need help div */}
              <div className="text-white mt-10 flex flex-col w-full justify-center items-center">
                <div className="mt-1 relative w-full">
                  {/* help section */}
                  <div className="flex justify-center text-[15px]  w-full items-center gap-2">
                    <button onClick={showHelp} className="flex ">
                      <h3>Need Help</h3>
                    </button>
                    <button onClick={showHelp}>
                      <img src={iconHelp} className="w-[30px]" alt="" />
                    </button>
                  </div>
                  {/* end of the help section */}
                </div>
              </div>

              {/* loading information for the add user and verify user  */}
              {userStatus && userStatus.state ? <div className="w-full items-center justify-center text-white absolute -bottom-14 flex pr-14 animate-pulse ">
                <div class="loader2 scale-50 left-0"></div>
                {/* changing contetn */}
                <div>
                  <h3 className="text-bethel-newgreen">{userStatus && userStatus.msg}</h3>


                </div>
              </div> : ""}

              {userStatus.fail && <h3 className="text-red-500 mt-5 text-[18px] animate__animated animate__fadeIn font-bold">{userStatus.failmsg}</h3>}


            </div>
          </div>

          {/* help about section for pc */}
          {showHelpSec && (
            <div className="flex fixed min-[1100px]:flex lg:hidden md:hidden sm:hidden min-[320px]:hidden h-[80%] p-4 flex-col gap-2 w-[300px] border-l-[.1px] justify-center z-[200] right-[0px]">
              {showHelp && (
                <div className="absolute lg:flex md:hidden sm:hidden min-[320px]:hidden left-[-400px] top-[0px] flex items-center gap-5">
                  <h3 className="text-[30px] text-white">
                  </h3>
                  {/* <span class="material-symbols-outlined text-white scale-[2.5] mt-2">
                    trending_flat
                  </span> */}
                </div>
              )}


              {/* paragraph section */}

              {/* changing instruction for sign up div */}
              {!toggleLoginInstruction && (
                <div className="flex w-full h-full">
                  {/* selected device option */}
                  {stepDevice && (
                    <div className="w-full relative p-1 border-white rounded-md animate__animated animate__fadeIn">
                      <div className="absolute top-[-25px] right-12 text-[#a3d902] text-[20px] font-bold ">
                        One-time Setup
                      </div>

                      <h3 className="mt-2 font-bold mb-2">
                        Please follow below instructions
                      </h3>
                      <h3 className="mt-2 mb-2">
                        Select the Device to continue
                      </h3>

                      {/* downlaod app div */}
                      <div className="w-full flex flex-col items-center gap-2 ">
                        <div className="flex gap-2 items-center justify-center">
                          {/* apple icon */}
                          <button
                            onClick={selectDeviceStep}
                            className="relative"
                          >
                            <img src={iconIoss} className="w-[110px]" alt="" />
                          </button>

                          {/* android icon */}
                          <button
                            onClick={selectDeviceStepAndroid}
                            className="relative "
                          >
                            <img
                              src={iconAndroid}
                              className="w-[100px]"
                              alt=""
                            />
                          </button>
                        </div>
                      </div>
                    </div>
                  )}

                  {/* fist step */}
                  {step1 && (
                    <div className="w-full relative p-1 border-white rounded-md animate__animated animate__fadeIn">
                      <div className=" top-[-45px] right-20 text-[#a3d902] text-[20px] font-bold mb-2">
                        Step 1 of 14
                      </div>
                      <h3 className=" font-bold mb-2">
                        Download and Install free{" "}
                        <span className="text-purple-600">Polygon ID</span> app
                      </h3>

                      {/* downlaod app div */}
                      <div className="w-full flex flex-col items-center gap-2">
                        <div className="flex gap-2 items-center justify-center">
                          {/* apple icon */}
                          {appStoreLink && <button onClick={toggleIosfn} className="relative">
                            <img
                              src={applestore}
                              className="w-[110px]"
                              alt=""
                            />
                          </button>}

                          {/* android icon */}
                          {!appStoreLink && <button
                            onClick={toggleAndoidfn}
                            className="relative "
                          >
                            <img
                              src={androidstore}
                              className="w-[100px]"
                              alt=""
                            />
                          </button>}
                        </div>

                        {/* toggle QR for download mobile App */}
                        <div>
                          {toggleIos && (
                            <div className=" p-1 rounded-md top-14 left-2 bg-white">
                              <img
                                src={iosQR}
                                className="w-[120px] p-2 "
                                alt=""
                              />
                            </div>
                          )}

                          {toggleAndroid && (
                            <div className=" p-1 rounded-md top-14 left-2 bg-white">
                              <img
                                src={androidQR}
                                className="w-[120px] p-2 "
                                alt=""
                              />
                            </div>
                          )}
                        </div>
                        {/* end of download mobile app */}
                      </div>
                    </div>
                  )}

                  {step1 && (
                    <div className="flex absolute bottom-[20px] w-full justify-center">
                      <button
                        onClick={step1prevfn}
                        className="flex w-full justify-center items-center mt-2"
                      >
                        <span class="material-symbols-outlined">
                          chevron_left
                        </span>
                        <h3 className="text-[20px]">Prev</h3>
                      </button>

                      <button
                        onClick={step1Next}
                        className="flex w-full justify-center items-center mt-2"
                      >
                        {" "}
                        {/* step3fn */}
                        <h3 className="text-[20px]">Next</h3>
                        <span class="material-symbols-outlined">
                          chevron_right
                        </span>
                      </button>
                    </div>
                  )}
                  {/* end first step */}

                  {/* 8 step 2 of 14 */}
                  {step8 && (
                    <div className="w-full relative p-1 border-white rounded-md gap-4 flex-col animate__animated animate__fadeIn">
                      <div className="mb-2 right-20 text-[#a3d902] text-[20px] font-bold">
                        Step 2 of 14
                      </div>

                      {/* downlaod app div */}
                      <div className="w-full flex flex-col items-center gap-2 justify-center mt-4">
                        {/* image in app */}
                        <div className="flex w-full justify-center">
                          <img src={image1_1} className="w-[90%]" alt="" />
                        </div>
                      </div>
                    </div>
                  )}

                  {step8 && (
                    <div className="flex w-full absolute justify-center bottom-[20px]">
                      {/* next step */}
                      <button
                        onClick={step8prev}
                        className="flex w-full justify-center items-center mt-2"
                      >
                        <span class="material-symbols-outlined">
                          chevron_left
                        </span>
                        <h3 className="text-[20px]">Prev</h3>
                      </button>

                      <button
                        onClick={step8Next}
                        className="flex w-full justify-center items-center mt-2"
                      >
                        <h3 className="text-[20px]">Next</h3>
                        <span class="material-symbols-outlined">
                          chevron_right
                        </span>
                      </button>
                    </div>
                  )}
                  {/* end of step 8 */}

                  {/* 9 step  3 of 14 */}
                  {step9 && (
                    <div className="w-full relative p-1 border-white rounded-md gap-4 flex-col animate__animated animate__fadeIn">
                      <div className="mb-2 right-20 text-[#a3d902] text-[20px] font-bold">
                        Step 3 of 14
                      </div>

                      {/* downlaod app div */}
                      <div className="w-full flex flex-col items-center gap-2 justify-center mt-4">
                        {/* image in app */}
                        <div className="flex w-full justify-center">
                          <img src={image1_2} className="w-[90%]" alt="" />
                        </div>
                      </div>
                    </div>
                  )}

                  {step9 && (
                    <div className="flex w-full absolute justify-center bottom-[20px]">
                      {/* next step */}
                      <button
                        onClick={step1Next}
                        className="flex w-full justify-center items-center mt-2"
                      >
                        <span class="material-symbols-outlined">
                          chevron_left
                        </span>
                        <h3 className="text-[20px]">Prev</h3>
                      </button>

                      <button
                        onClick={step9Next}
                        className="flex w-full justify-center items-center mt-2"
                      >
                        <h3 className="text-[20px]">Next</h3>
                        <span class="material-symbols-outlined">
                          chevron_right
                        </span>
                      </button>
                    </div>
                  )}
                  {/* end of step 9 */}

                  {/* 10 step 4 of 14 */}
                  {step10 && (
                    <div className="w-full relative p-1 border-white rounded-md gap-4 flex-col animate__animated animate__fadeIn">
                      <div className="mb-2 right-20 text-[#a3d902] text-[20px] font-bold">
                        Step 4 of 14
                      </div>

                      {/* downlaod app div */}
                      <div className="w-full flex flex-col items-center gap-2 justify-center mt-4">
                        {/* image in app */}
                        <div className="flex w-full justify-center">
                          <img src={image1_3} className="w-[90%]" alt="" />
                        </div>
                      </div>
                    </div>
                  )}

                  {step10 && (
                    <div className="flex w-full absolute justify-center bottom-[20px]">
                      {/* next step */}
                      <button
                        onClick={step8Next}
                        className="flex w-full justify-center items-center mt-2"
                      >
                        <span class="material-symbols-outlined">
                          chevron_left
                        </span>
                        <h3 className="text-[20px]">Prev</h3>
                      </button>

                      <button
                        onClick={step10Next}
                        className="flex w-full justify-center items-center mt-2"
                      >
                        <h3 className="text-[20px]">Next</h3>
                        <span class="material-symbols-outlined">
                          chevron_right
                        </span>
                      </button>
                    </div>
                  )}
                  {/* end of step 10 */}

                  {/* 11 step 5 of 14 */}
                  {step11 && (
                    <div className="w-full relative p-1 border-white rounded-md gap-4 flex-col animate__animated animate__fadeIn">
                      <div className="mb-2 right-20 text-[#a3d902] text-[20px] font-bold">
                        Step 5 of 14
                      </div>

                      {/* downlaod app div */}
                      <div className="w-full flex flex-col items-center gap-2 justify-center mt-4">
                        {/* image in app */}
                        <div className="flex w-full justify-center">
                          <img src={image1_4} className="w-[90%]" alt="" />
                        </div>
                      </div>
                    </div>
                  )}

                  {step11 && (
                    <div className="flex w-full absolute justify-center bottom-[20px]">
                      {/* next step */}
                      <button
                        onClick={step9Next}
                        className="flex w-full justify-center items-center mt-2"
                      >
                        <span class="material-symbols-outlined">
                          chevron_left
                        </span>
                        <h3 className="text-[20px]">Prev</h3>
                      </button>

                      <button
                        onClick={step11Next}
                        className="flex w-full justify-center items-center mt-2"
                      >
                        <h3 className="text-[20px]">Next</h3>
                        <span class="material-symbols-outlined">
                          chevron_right
                        </span>
                      </button>
                    </div>
                  )}
                  {/* end of step 11 */}

                  {/* 12 step 6 of 14 */}
                  {step12 && (
                    <div className="w-full relative p-1 border-white rounded-md gap-4 flex-col animate__animated animate__fadeIn">
                      <div className="mb-2 right-20 text-[#a3d902] text-[20px] font-bold">
                        Step 6 of 14
                      </div>

                      {/* downlaod app div */}
                      <div className="w-full flex flex-col items-center gap-2 justify-center mt-4">
                        {/* image in app */}
                        <div className="flex w-full justify-center">
                          <img src={image1_5} className="w-[90%]" alt="" />
                        </div>
                      </div>
                    </div>
                  )}

                  {step12 && (
                    <div className="flex w-full absolute justify-center bottom-[20px]">
                      {/* next step */}
                      <button
                        onClick={step10Next}
                        className="flex w-full justify-center items-center mt-2"
                      >
                        <span class="material-symbols-outlined">
                          chevron_left
                        </span>
                        <h3 className="text-[20px]">Prev</h3>
                      </button>

                      <button
                        onClick={step12Next}
                        className="flex w-full justify-center items-center mt-2"
                      >
                        <h3 className="text-[20px]">Next</h3>
                        <span class="material-symbols-outlined">
                          chevron_right
                        </span>
                      </button>
                    </div>
                  )}
                  {/* end of step 12 */}

                  {/* 13 step 7 of 14 */}
                  {step13 && (
                    <div className="w-full relative p-1 border-white rounded-md gap-4 flex-col animate__animated animate__fadeIn">
                      <div className="mb-2 right-20 text-[#a3d902] text-[20px] font-bold">
                        Step 7 of 14
                      </div>

                      {/* downlaod app div */}
                      <div className="w-full flex flex-col items-center gap-2 justify-center mt-4">
                        {/* image in app */}
                        <div className="flex w-full justify-center">
                          <img src={image1_6} className="w-[90%]" alt="" />
                        </div>
                      </div>
                    </div>
                  )}

                  {step13 && (
                    <div className="flex w-full absolute justify-center bottom-[20px]">
                      {/* next step */}
                      <button
                        onClick={step11Next}
                        className="flex w-full justify-center items-center mt-2"
                      >
                        <span class="material-symbols-outlined">
                          chevron_left
                        </span>
                        <h3 className="text-[20px]">Prev</h3>
                      </button>

                      <button
                        onClick={step13Next}
                        className="flex w-full justify-center items-center mt-2"
                      >
                        <h3 className="text-[20px]">Next</h3>
                        <span class="material-symbols-outlined">
                          chevron_right
                        </span>
                      </button>
                    </div>
                  )}
                  {/* end of step 13 */}

                  {/* 14 step 8 of 14 */}
                  {step14 && (
                    <div className="w-full relative p-1 border-white rounded-md gap-4 flex-col animate__animated animate__fadeIn">
                      <div className="mb-2 right-20 text-[#a3d902] text-[20px] font-bold">
                        Step 8 of 14
                      </div>

                      {/* downlaod app div */}
                      <div className="w-full flex flex-col items-center gap-2 justify-center mt-4">
                        {/* image in app */}
                        <div className="flex w-full justify-center">
                          <img src={image1_7} className="w-[90%]" alt="" />
                        </div>
                      </div>
                    </div>
                  )}

                  {step14 && (
                    <div className="flex w-full absolute justify-center bottom-[20px]">
                      {/* next step */}
                      <button
                        onClick={step12Next}
                        className="flex w-full justify-center items-center mt-2"
                      >
                        <span class="material-symbols-outlined">
                          chevron_left
                        </span>
                        <h3 className="text-[20px]">Prev</h3>
                      </button>

                      <button
                        onClick={step14Next}
                        className="flex w-full justify-center items-center mt-2"
                      >
                        <h3 className="text-[20px]">Next</h3>
                        <span class="material-symbols-outlined">
                          chevron_right
                        </span>
                      </button>
                    </div>
                  )}
                  {/* end of step 13 */}

                  {/* step 1.1  9 of 14 */}
                  {step1_1 && (
                    <div className="w-full relative p-1 border-white rounded-md animate__animated animate__fadeIn">
                      <div className="mb-4 right-20 text-[#a3d902] text-[20px] font-bold">
                        Step 9 of 14
                      </div>

                      {/* downlaod app div */}
                      <div className="w-full flex flex-col items-center gap-2">
                        {/* image in app */}
                        {deviceSelected ? (
                          <div>
                            <img src={Image5} className="w-full ml-6" alt="" />
                          </div>
                        ) : (
                          <div>
                            <img src={Image4} className="w-full " alt="" />
                          </div>
                        )}
                      </div>
                    </div>
                  )}

                  {step1_1 && (
                    <div className="flex w-full absolute justify-center bottom-[20px]">
                      <button
                        onClick={step13Next}
                        className="flex w-full justify-center items-center mt-2"
                      >
                        <span class="material-symbols-outlined">
                          chevron_left
                        </span>
                        <h3 className="text-[20px]">Prev</h3>
                      </button>

                      <button
                        onClick={nextStep11}
                        className="flex w-full justify-center items-center mt-2"
                      >
                        <h3 className="text-[20px]">Next</h3>
                        <span class="material-symbols-outlined">
                          chevron_right
                        </span>
                      </button>
                    </div>
                  )}
                  {/* end of 1.1 */}

                  {/* 2.1 step 10 of 14 */}
                  {step4 && (
                    <div className="w-full relative p-1 border-white rounded-md gap-4 flex-col animate__animated animate__fadeIn">
                      <div className="mb-2 right-20 text-[#a3d902] text-[20px] font-bold">
                        Step 10 of 14
                      </div>

                      {/* downlaod app div */}
                      <div className="w-full flex flex-col items-center gap-2 justify-center mt-4">
                        {/* image in app */}
                        <div className="flex w-full justify-center">
                          <img src={Image7} className="w-[90%]" alt="" />
                        </div>
                      </div>
                    </div>
                  )}

                  {step4 && (
                    <div className="flex w-full absolute justify-center bottom-[20px]">
                      {/* next step */}
                      <button
                        onClick={step1prev}
                        className="flex w-full justify-center items-center mt-2"
                      >
                        <span class="material-symbols-outlined">
                          chevron_left
                        </span>
                        <h3 className="text-[20px]">Prev</h3>
                      </button>

                      <button
                        onClick={step2fn}
                        className="flex w-full justify-center items-center mt-2"
                      >
                        <h3 className="text-[20px]">Next</h3>
                        <span class="material-symbols-outlined">
                          chevron_right
                        </span>
                      </button>
                    </div>
                  )}

                  {/* 2.2 step 11 of 14 */}
                  {step2 && (
                    <div className="w-full relative p-1 border-white rounded-md gap-4 flex-col animate__animated animate__fadeIn ">
                      <div className="mb-2 text-[#a3d902] text-[20px] font-bold ">
                        Step 11 of 14
                      </div>

                      {/* downlaod app div */}
                      <div className="w-full flex flex-col items-center gap-2 mt-4">
                        {/* image in app */}
                        <div className="flex w-full justify-center">
                          <img src={Image9} className="w-[90%]" alt="" />
                        </div>
                      </div>
                    </div>
                  )}

                  {step2 && (
                    <div className="flex w-full absolute justify-center bottom-[20px]">
                      {/* next step */}
                      <button
                        onClick={step3prev}
                        className="flex w-full justify-center items-center mt-2"
                      >
                        <span class="material-symbols-outlined">
                          chevron_left
                        </span>
                        <h3 className="text-[20px]">Prev</h3>
                      </button>

                      <button
                        onClick={step4fn}
                        className="flex w-full justify-center items-center mt-2"
                      >
                        <h3 className="text-[20px]">Next</h3>
                        <span class="material-symbols-outlined">
                          chevron_right
                        </span>
                      </button>
                    </div>
                  )}
                  {/* end of second step */}

                  {/* third step 12 of 14*/}
                  {step3 && (
                    <div className="w-full relative p-1 border-white rounded-md gap-4 flex-col animate__animated animate__fadeIn">
                      <div className="mb-2 text-[#a3d902] text-[20px] font-bold">
                        Step 12 of 14
                      </div>

                      {/* downlaod app div */}
                      <div className="w-full flex flex-col items-center gap-2 mt-4">
                        {/* image in app */}
                        <div className="flex w-full justify-center">
                          <img src={Image11} className="w-[90%]" alt="" />
                        </div>
                      </div>
                    </div>
                  )}

                  {step3 && (
                    <div className="flex w-full absolute justify-center bottom-[20px]">
                      {/* next step */}
                      <button
                        onClick={step4prev}
                        className="flex w-full justify-center items-center mt-2"
                      >
                        <span class="material-symbols-outlined">
                          chevron_left
                        </span>
                        <h3 className="text-[20px]">Prev</h3>
                      </button>

                      <button
                        onClick={step5fn}
                        className="flex w-full justify-center items-center mt-2"
                      >
                        <h3 className="text-[20px]">Next</h3>
                        <span class="material-symbols-outlined">
                          chevron_right
                        </span>
                      </button>
                    </div>
                  )}
                  {/* end of third step */}

                  {/* third step  13 of 14*/}
                  {step5 && (
                    <div className="w-full relative p-1 border-white rounded-md gap-4 flex-col animate__animated animate__fadeIn">
                      <div className="mb-2 text-[#a3d902] text-[20px] font-bold">
                        Step 13 of 14
                      </div>

                      {/* downlaod app div */}
                      <div className="w-full flex flex-col items-center gap-2 mt-4">
                        {/* image in app */}
                        <div className="rounded-md mb-2  p-2">
                          <img src={Image2} className="w-full" alt="" />
                        </div>
                      </div>
                    </div>
                  )}

                  {step5 && (
                    <div className="flex w-full absolute justify-center bottom-[20px]">
                      {/* next step */}
                      <button
                        onClick={step4fn}
                        className="flex w-full justify-center items-center mt-2"
                      >
                        <span class="material-symbols-outlined">
                          chevron_left
                        </span>
                        <h3 className="text-[20px]">Prev</h3>
                      </button>

                      <button
                        onClick={step6fn}
                        className="flex w-full justify-center items-center mt-2"
                      >
                        <h3 className="text-[20px]">Next</h3>
                        <span class="material-symbols-outlined">
                          chevron_right
                        </span>
                      </button>
                    </div>
                  )}
                  {/* end of third step */}

                  {/* third step 14 of 14*/}
                  {step6 && (
                    <div className="w-full relative p-1 border-white rounded-md gap-4 flex-col animate__animated animate__fadeIn">
                      <div className="mb-2 text-[#a3d902] text-[20px] font-bold">
                        Step 14 of 14
                      </div>

                      {/* downlaod app div */}
                      <div className="w-full flex flex-col items-center gap-2 mt-6">
                        {/* image in app */}
                        {/* <div className='rounded-md mb-2 p-2'>
                      <img src={pasteDid} className="w-full" alt="" />
                    </div> */}
                      </div>

                      <h3 className="mt-2 text-[12px] p-2 border-2 border-red-600 rounded-md">
                        1. Paste your Polygon ID where indicated.
                        <br /> 2. Click Submit to view the Sign Up QR code
                      </h3>
                    </div>
                  )}

                  {step6 && (
                    <div className="flex w-full absolute justify-center bottom-[20px]">
                      {/* next step */}
                      <button
                        onClick={step5fn}
                        className="flex w-full justify-center items-center mt-2"
                      >
                        <span class="material-symbols-outlined">
                          chevron_left
                        </span>
                        <h3 className="text-[20px]">Prev</h3>
                      </button>

                      <button
                        onClick={showHelp}
                        className="flex w-full justify-center items-center mt-2"
                      >
                        <h3 className="text-[20px]">Finish</h3>

                        <span class="material-symbols-outlined">
                          chevron_right
                        </span>
                      </button>
                    </div>
                  )}
                  {/* end of third step */}

                  {/* 7 step step */}
                  {step7 && (
                    <div className="w-full relative p-1 border-white rounded-md gap-4 flex-col animate__animated animate__fadeIn">
                      <div className="absolute top-[-15px] right-20 text-[#a3d902] text-[20px] font-bold">
                        Step 8 of 8
                      </div>

                      {/* downlaod app div */}
                      <div className="w-full flex flex-col items-center gap-2 mt-4">
                        {/* image in app */}
                        <div className="rounded-md mt-6 p-2">
                          <img src={singupQRImg} className="w-full" alt="" />
                        </div>
                      </div>
                    </div>
                  )}

                  {step7 && (
                    <div className="flex w-full absolute justify-center bottom-[20px]">
                      {/* next step */}
                      <button
                        onClick={step6fn}
                        className="flex w-full justify-center items-center mt-2"
                      >
                        <span class="material-symbols-outlined">
                          chevron_left
                        </span>
                        <h3 className="text-[20px]">Prev</h3>
                      </button>

                      <button
                        onClick={step1prevfn}
                        className="flex w-full justify-center items-center mt-2"
                      >
                        <span class="material-symbols-outlined">
                          chevron_left
                        </span>
                        <h3 className="text-[20px]">Finish</h3>

                        <span class="material-symbols-outlined">
                          chevron_right
                        </span>
                      </button>
                    </div>
                  )}
                  {/* end of third step */}
                </div>
              )}

              {/* changing instrucution for log in */}
              {toggleLoginInstruction && (
                <div className="flex w-full h-full">
                  {/* 0  log step */}
                  {logstep0 && (
                    <div className="w-full relative p-1 border-white rounded-md animate__animated animate__fadeIn h-full">
                      <div className="absolute top-[-25px] right-[80px] text-[#a3d902] text-[20px] font-bold">
                        Step 1 of 5
                      </div>
                      <h3 className="mt-2 font-bold mb-4">
                        Here is the Login Instructions
                      </h3>

                      {/* downlaod app div */}
                      <div className="w-full flex flex-col items-center gap-2">
                        {/* image in app */}
                        <div>
                          <img src={step1Ios} className="w-full" alt="" />
                        </div>
                      </div>
                    </div>
                  )}
                  {/* end 0 step */}

                  {/* next step */}
                  {logstep0 && (
                    <button
                      onClick={logStep0next}
                      className="flex w-full justify-center items-center mt-2 absolute bottom-[20px]"
                    >
                      <h3 className="text-[20px]">Next</h3>
                      <span class="material-symbols-outlined">
                        chevron_right
                      </span>
                    </button>
                  )}

                  {/* fist log step */}
                  {logstep1 && (
                    <div className="w-full relative p-1 border-white rounded-md animate__animated animate__fadeIn">
                      <div className="absolute top-[-25px] right-[80px] text-[#a3d902] text-[20px] font-bold">
                        Step 2 of 5
                      </div>
                      {/* <h3 className='mt-2 font-bold mb-4'>Here is the Login Instructions</h3> */}

                      {/* downlaod app div */}
                      <div className="w-full flex flex-col items-center gap-2">
                        <h3 className="text-[14px] text-white w-full">
                          1. Open{" "}
                          <span className="text-purple-600">Polygon ID </span>
                          app and follow these steps
                        </h3>

                        {/* image in app */}
                        <div>
                          <img src={logStep1Img} className="w-[90%]" alt="" />
                        </div>
                      </div>
                    </div>
                  )}

                  {/* next step */}
                  {logstep1 && (
                    <div className="flex absolute bottom-[20px] w-full justify-center">
                      {/* next step */}
                      <button
                        onClick={logstep1prevfn}
                        className="flex w-full justify-center items-center mt-2"
                      >
                        <span class="material-symbols-outlined">
                          chevron_left
                        </span>
                        <h3 className="text-[20px]">Prev</h3>
                      </button>

                      <button
                        onClick={logstep1fn}
                        className="flex w-full justify-center items-center mt-2"
                      >
                        <h3 className="text-[20px]">Next</h3>
                        <span class="material-symbols-outlined">
                          chevron_right
                        </span>
                      </button>
                    </div>
                  )}
                  {/* end first step */}

                  {/* seceond log step  */}
                  {logstep2 && (
                    <div className="w-full relative p-1 border-white rounded-md animate__animated animate__fadeIn">
                      <div className="absolute top-[-35px] right-[80px] text-[#a3d902] text-[20px] font-bold">
                        Step 3 of 5
                      </div>
                      {/* <h3 className='mt-2 font-bold '>Here is the Login Instructions</h3> */}

                      {/* downlaod app div */}
                      <div className="w-full flex flex-col items-center gap-2">
                        <h3 className="text-[14px] text-white w-full">
                          2. After scanning please change Private Profile into
                          Public
                        </h3>

                        {/* image in app */}
                        <div>
                          <img src={profileChange} className="w-full" alt="" />
                        </div>
                      </div>
                    </div>
                  )}
                  {/* end second log step */}
                  {logstep2 && (
                    <div className="flex absolute bottom-[20px] w-full justify-center">
                      <button
                        onClick={logstep1fnprev}
                        className="flex w-full justify-center items-center mt-2"
                      >
                        <span class="material-symbols-outlined">
                          chevron_left
                        </span>
                        <h3 className="text-[20px]">Prev</h3>
                      </button>

                      <button
                        onClick={logStep3fn}
                        className="flex w-full justify-center items-center mt-2"
                      >
                        <h3 className="text-[20px]">Next</h3>

                        <span class="material-symbols-outlined">
                          chevron_right
                        </span>
                      </button>
                    </div>
                  )}

                  {/* third log step  */}
                  {logstep3 && (
                    <div className="w-full relative p-1 border-white rounded-md animate__animated animate__fadeIn">
                      <div className="absolute top-[-25px] right-[80px] text-[#a3d902] text-[20px] font-bold">
                        Step 4 of 5
                      </div>
                      {/* <h3 className='mt-2 font-bold '>Here is the Login Instructions</h3> */}

                      {/* downlaod app div */}
                      <div className="w-full flex flex-col items-center gap-2">
                        <h3 className="text-[14px] text-white w-full">
                          3. Change into Public
                        </h3>

                        {/* image in app */}
                        <div>
                          <img src={profileChange1} className="w-full" alt="" />
                        </div>
                      </div>
                    </div>
                  )}

                  {/* next step */}
                  {logstep3 && (
                    <div className="flex w-full justify-center absolute bottom-[20px]">
                      <button
                        onClick={logstep1fn}
                        className="flex w-full justify-center items-center mt-2"
                      >
                        <span class="material-symbols-outlined">
                          chevron_left
                        </span>
                        <h3 className="text-[20px]">Prev</h3>
                      </button>

                      <button
                        onClick={nextStep4}
                        className="flex w-full justify-center items-center mt-2"
                      >
                        <h3 className="text-[20px]">Next</h3>

                        <span class="material-symbols-outlined">
                          chevron_right
                        </span>
                      </button>
                    </div>
                  )}
                  {/* end second log step */}

                  {/* 4th log step  */}
                  {logStep4 && (
                    <div className="w-full relative p-1 border-white rounded-md animate__animated animate__fadeIn">
                      <div className="absolute top-[-25px] right-[80px] text-[#a3d902] text-[20px] font-bold">
                        Step 5 of 5
                      </div>
                      {/* <h3 className='mt-2 font-bold '>Here is the Login Instructions</h3> */}

                      {/* downlaod app div */}
                      <div className="w-full flex flex-col items-center gap-2">
                        <h3 className="text-[14px] text-white w-full">
                          3. Then press Approve and wait until process is
                          complete
                        </h3>

                        {/* image in app */}
                        <div>
                          <img src={logStep5Img} className="w-full" alt="" />
                        </div>
                      </div>
                    </div>
                  )}

                  {/* next step */}
                  {logStep4 && (
                    <div className="flex w-full justify-center absolute bottom-[20px]">
                      <button
                        onClick={logstep1fn}
                        className="flex w-full justify-center items-center mt-2"
                      >
                        <span class="material-symbols-outlined">
                          chevron_left
                        </span>
                        <h3 className="text-[20px]">Prev</h3>
                      </button>

                      {/* <button
                        onClick={logstep1prevfn}
                        className="flex w-full justify-center items-center mt-2"
                      >
                        <span class="material-symbols-outlined">
                          chevron_left
                        </span>
                        <h3 className="text-[20px]">Finish</h3>

                        <span class="material-symbols-outlined">
                          chevron_right
                        </span>
                      </button> */}
                    </div>
                  )}
                  {/* end second log step */}
                </div>
              )}
            </div>
          )}

          {/* help about section for mobile responsive*/}
          {isoDevice ? (
            <div className={`lg:flex md:hidden sm:hidden min-[320px]:hidden bg-black w-full h-full fixed z-[99999] justify-center flex-col items-center md:px-20 sm:px-20 min-[320px]:px-10`}>

              <div className="w-full flex  justify-center">
                <h3 className="font-bold text-bethel-green text-[20px]">Mainnet.bethel.network</h3>
              </div>

              <hr className="text-white bg-white mt-2 w-[90%] opacity-30 mb-2" />

              <div className="flex w-full justify-center animate-pulse">
                <img src={BethelImg} className="w-[150px]" alt="" />
              </div>

              <div className="text-white font-semibold min-[320px]:text-[15px] md:text-[25px] sm:text-[25px] w-full justify-center items-center flex text-center flex-col py-4">
                <Typewriter className="text-center w-[100%] text-red-500"
                  options={{
                    strings: ["Some parts of Bethel zkpStorage may not work on a mobile device. Apple and Android Support will be enabled shortly."],
                    autoStart: true,
                    cursor: "_",
                    delay: 60,
                    deleteSpeed: Infinity, // Lower value for faster typing speed
                  }}
                />
              </div>

              <a href="https://bethelnet.io/" className="p-2 font-bold text-bethel-green border border-bethel-green rounded-lg mt-2">Take me to BETHELNET.IO</a>
            </div>
          ) : (
            ""
          )}
        </div>
        {/* end of QR section */}

        {/* end of sign in content */}

        {/* server error msg */}
        {serverErrorFetch && (
          <div className="flex flex-col items-center w-full ">
            <h1 className="text-white text-[25px] font-bold py-2 mb-24">
              Service is not Available
            </h1>
            {/* <img className="w-[250px]" src={badRequest} alt="" /> */}
          </div>
        )}
      </div>

      <div className="absolute bottom-[10px] right-5 z-[999]">
        <h3 className="text-white text-[10px] mt-2">Version {version}</h3>
      </div>

      {/* meta mask warrning section */}
      {metamaskWarning && <InstallMetamaskComp onBtnClick={closeMetaMaskWarning} />}

      {/* adding user warning section */}
      {/* {toggleWarning && <CloseWarning walletAdd={curretWalletAddress} onBtnClickCont={handleDidSubmit} onBtnClickClose={toggleWarningFn} />} */}

      {linkwalletstatus && <UserValidation w_address={linkedWallets} onBtnClick={() => setLinkwalletstatus(false)} />}

      {walletaddressvalidation && !linkwalletstatus && <WalletAddressValidation onBtnClick={() => setWalletaddressvalidation(false)} />}

      {!toggleAuthWarning && <LoginValidationCheck onBtnClick={() => setToggleAuthWarning(true)} authAddress={authWalletAddress} />}

    </section>
  );
}

export default Login;