import React, { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import iconReferral from "../Images/icons/icon-referrals.png"
import copyIcon from "../Images/icons/icon-copy.png"
import { ethers } from 'ethers'
import iconMedal1 from "../Images/icons/icon-medal1.png"
import iconMedal2 from "../Images/icons/icon-medal2.png"
import iconMedal3 from "../Images/icons/icon-medal3.png"
import iconMedal from "../Images/icons/icon-trophie.png"
import iconMedalNone from "../Images/icons/icon-medalnone.png"
import bethelLogo from "../Images/icons/bethel-logo.png"

function ReferralsComponent() {
    const userDid = useSelector((state) => state.DidReducer)
    const avalibilityData = useSelector((state) => state.requireAvalibilityReducer)
    const inputRef = useRef()
    const [copied, setCopied] = useState(false)

    const [refCount, setRefCount] = useState(null)

    // referral link
    const [refLink, setRefLink] = useState(null)

    const handleCopy = () => {
        if (inputRef.current) {
            navigator.clipboard.writeText(inputRef.current.defaultValue)
                .then(() => {
                    setCopied(true)

                    setTimeout(() => {
                        setCopied(false)
                    }, 1000);
                })
                .catch(err => {
                    console.error('Failed to copy text: ', err);
                });
        }
    };

    const refLinkfn = async () => {
        try {
            const res = await fetch(process.env.REACT_APP_BACKEND_URL + "get-ref-id", {
                method: "POST",
                body: JSON.stringify({
                    "DID": userDid
                })
            })
            const ref = await res.json()
            setRefLink(`https://mainnet.bethel.network/ref?${ref.RefID}`)
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        refLinkfn()
        getRefCount()
        checkRef()
        getPackageDetils()
        get_leaderboard_details()
        get_my_rank()
    }, [])

    // get referral count 
    const getRefCount = async () => {
        try {
            const res = await fetch(process.env.REACT_APP_BACKEND_URL + "get-counts", {
                method: "POST",
                body: JSON.stringify({
                    "DID": userDid
                })
            })
            const ref = await res.json()
            setRefCount(ref)
        } catch (error) {
            console.log(error)
        }
    }

    //check if ref is available
    const [isRef, setIfRef] = useState(false)

    const checkRef = async () => {
        try {
            const res = await fetch(process.env.REACT_APP_BACKEND_URL + `check-ref?did=${userDid}`)
            const ref = await res.json()
            setIfRef(ref.Status)
        } catch (error) {
            console.log(error)
        }
    }

    // genarate link
    const genarateLink = async () => {
        try {
            const res = await fetch(process.env.REACT_APP_BACKEND_URL + `get-refID?did=${userDid}&package=MONTH_${packageDuration}_${packageType}`)

            if (res.status === 200) {
                const ref = await res.json()
                setRefLink(`https://mainnet.bethel.network/ref?${ref.RefID}`)
            }
        } catch (error) {
            console.log(error)

        }
    }

    // get package details 
    const fileStorageJSON2 = require("../json/FileStorage2.json");
    const fileStorageAddress2 = process.env.REACT_APP_BLOCK_CHAIN_CONTRACT_ADDRESS_2
    const fileStorageABI2 = fileStorageJSON2.abi

    const [packageType, setPackageType] = useState(null)
    const [packageDuration, setPackageDuration] = useState(null)

    const getPackageDetils = async () => {
        try {
            const provider = new ethers.providers.Web3Provider(window.ethereum)
            const signer = provider.getSigner()
            const address = await signer.getAddress()
            const contract = new ethers.Contract(fileStorageAddress2, fileStorageABI2, signer)

            const packStatus = await contract.getCurrentPackage(userDid, address)
            setPackageDuration(parseInt(packStatus[2]._hex))

            // set package type
            switch (packStatus[1]) {
                case "Basic":
                    setPackageType("BASIC")
                    break;
                case "Starter":
                    setPackageType("STARTER")
                    break;
                case "Advance":
                    setPackageType("ADVANCE")
                    break;
                default:
                    console.log("")
            }


        } catch (e) {
            console.log(e)
        }
    }

    //get learderboard details
    const [leaderboarder_details, setLeaderboard_details] = useState(null)
    const [my_rank, setMy_rank] = useState(null)
    const rankRef = useRef()

    const [toggle_leader_board, setToggle_leader_board] = useState(false)

    const get_leaderboard_details = async () => {
        try {
            const res = await fetch(process.env.REACT_APP_BACKEND_URL + "get-top-10-ref")
            if (res.ok) {
                const data = await res.json()
                setLeaderboard_details(data.TopReferrals)
            }
        } catch (error) {
            console.log("error in leaderboard details :", error)
        }
    }

    const get_my_rank = async () => {
        try {
            const res = await fetch(process.env.REACT_APP_BACKEND_URL + "get-user-ref-rank", {
                method: "POST",
                body: JSON.stringify({
                    "DID": userDid
                })
            })
            if (res.ok) {
                const data = await res.json()
                console.log(data)
                setMy_rank(data.Rank)
            }
        } catch (error) {
            console.log("error in leaderboard details :", error)
        }
    }

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const handleClickOutside = (event) => {
        if (rankRef.current && !rankRef.current.contains(event.target)) {
            setToggle_leader_board(false);
        }
    };

    return (
        <div className={`px-2 py-4 flex flex-col gap-3 ${!avalibilityData.allreadyRegisteredWallet && !avalibilityData.metamask && !avalibilityData.userAdded && !avalibilityData.noUser ? "" : "pointer-events-none grayscale blur-sm"}`}>
            {/* topic div */}
            <div className='text-white w-full flex items-center gap-1'>
                {/* image icon */}
                <div>
                    <img src={iconReferral} className='flex w-[25px]' alt="" />
                </div>

                <div className='font-normal text-[16px] text-white'>
                    <h3>REFERRALS</h3>
                </div>
            </div>
            {/* end of topic section */}

            <div className='w-full p-3 border border-white/20 mt-10 rounded-lg'>
                {/* referrals section */}
                <div className='flex w-full pb-4 gap-2'>
                    {/* referrals link section */}
                    <div className='w-[200px] px-4 py-2 bg-white/10 '>
                        <h3 className='text-white text-[14px]'>Your Referral Link </h3>
                    </div>

                    {refLink !== 'https://mainnet.bethel.network/ref?undefined' &&
                        <div className='w-full px-4 py-2 bg-bethel-green/10  border-l border-white/80 flex gap-2 items-center'>
                            <input disabled className='text-white text-[12px] bg-transparent w-full p-1' defaultValue={refLink} ref={inputRef} />
                            <button onClick={handleCopy} className={`${copied ? "bg-bethel-newgreen" : "bg-green-700"} p-1 rounded-full `}>
                                <img src={copyIcon} className='w-[15px] ' alt="" />
                            </button>
                        </div>}

                    {refLink === 'https://mainnet.bethel.network/ref?undefined' &&
                        <div className='w-full px-4 py-2 bg-bethel-green/10  border-l border-white/80 flex gap-2 items-center'>
                            <input disabled className='text-white text-[12px] bg-transparent w-full p-1' defaultValue="click genarate to get Reffaral link" />
                            <button onClick={handleCopy} className={`${copied ? "bg-bethel-newgreen" : "bg-green-700"} p-1 rounded-full `}>
                                <img src={copyIcon} className='w-[15px] ' alt="" />
                            </button>

                            <button onClick={genarateLink} className='px-2 py-1 border  rounded-lg text-white text-[0.8rem] hover:scale-105 hover:bg-bethel-green' >Genarate</button>
                        </div>}
                </div>

                {/* your referal counts */}
                <div className='py-2 bg-white/10 w-full px-2 flex justify-between items-center'>
                    <div className='w-full'>
                        <h3 className='text-white font-bold text-[1rem]'>Your Referral Count </h3>
                    </div>

                    {/* referral count */}
                    <div className=''>
                        <div className='p-2 rounded-full bg-[#0F0F0F] w-9 h-9 flex items-center justify-center'>
                            <h3 className='text-bethel-green font-bold '>{refCount && (refCount.RefCount ? refCount.RefCount : 0)}</h3>
                        </div>

                    </div>

                </div>
            </div>


            <div ref={rankRef} className="flex flex-col gap-2 w-full bg-black rounded-t-lg border-2 border-bethel-green right-2 p-3 z-[200] animate__animated animate__fadeInUp animate__faster h-full mt-2">

                {/* leaderboard */}
                <div className='w-full p-1 flex flex-col gap-2 z-[200] relative'>
                    {/* top section */}
                    <div className="flex gap-2 items-center justify-between tracking-wide p-2">
                        <div className="flex items-center gap-2 ">
                            <div>
                                <img src={bethelLogo} className="w-[20px]" alt="" />
                            </div>
                            <div className="font-bold text-bethel-green text-[1rem]">
                                <h3>BETHEL LEADERBOARD</h3>
                            </div>
                        </div>


                        <div className="text-white font-bold text-[10px] flex gap-2 items-center">
                            <h3 className='text-[14px]'>Your Rank :</h3>
                            <h3 className="relative text-bethel-green  z-10 font-bold rounded-full w-[35px] h-[35px] justify-center items-center flex text-[12px] border border-bethel-green">{my_rank && my_rank}</h3>

                        </div>
                    </div>
                    {/* end of top section */}

                    <hr className="h-[1px] opacity-20 w-full" />

                    <div className="flex w-full flex-col gap-2">
                        {leaderboarder_details && leaderboarder_details.slice(0, 3).map((ref, index) => {
                            return <div className="flex flex-col">
                                <div className="flex items-center px-2 py-1 gap-2">

                                    <div className="flex items-center w-full gap-2 ">
                                        {/* tropie image */}
                                        <div className={`flex`}>
                                            {index === 0 && <img src={iconMedal1} className="w-[45px] animate-pulse" alt="" />}
                                            {index === 1 && <img src={iconMedal2} className="w-[45px] opacity-80" alt="" />}
                                            {index === 2 && <img src={iconMedal3} className="w-[45px] opacity-60" alt="" />}
                                        </div>

                                        {/* refs */}
                                        {index === 0 && (
                                            <div className="relative w-full p-1 overflow-hidden rounded-l-lg flex justify-between animate-pulse items-center">
                                                <div className="bg-gradient-to-r from-green-600/100 via-green-400/100 to-transparent absolute inset-0 animate-fade-green"></div>
                                                <h3 className="relative text-white/70 text-[14px] z-10">{ref.ReferralID}</h3>
                                                <h3 className="relative text-white/70  z-10 font-bold rounded-full w-[35px] h-[35px] justify-center items-center flex text-[14px] border border-white/50">{ref.TotalPoint}</h3>
                                            </div>
                                        )}

                                        {index === 1 && (
                                            <div className="relative w-full p-1 overflow-hidden rounded-l-lg flex justify-between items-center">
                                                <div className="bg-gradient-to-r from-yellow-600/90 via-yellow-400/90 to-transparent absolute inset-0 animate-fade-green"></div>
                                                <h3 className="relative text-white/70 text-[14px] z-10">{ref.ReferralID}</h3>
                                                <h3 className="relative text-white/70  z-10 font-bold rounded-full w-[35px] h-[35px] justify-center items-center flex text-[14px] border border-white/50">{ref.TotalPoint}</h3>
                                            </div>
                                        )}

                                        {index === 2 && (
                                            <div className="relative w-full p-1 overflow-hidden rounded-l-lg flex justify-between items-center">
                                                <div className="bg-gradient-to-r from-orange-600/90 via-orange-400/90 to-transparent absolute inset-0 animate-fade-green"></div>
                                                <h3 className="relative text-white/70 text-[14px] z-10">{ref.ReferralID}</h3>
                                                <h3 className="relative text-white/70  z-10 font-bold rounded-full w-[35px] h-[35px] justify-center items-center flex text-[14px] border border-white/50">{ref.TotalPoint}</h3>

                                            </div>
                                        )}
                                    </div>

                                </div>

                            </div>
                        })}

                        {leaderboarder_details && leaderboarder_details.slice(3, 10).map((ref, index) => {
                            return <div className="flex flex-col">
                                <div className="flex items-center px-2 py-1 gap-2">

                                    <div className="flex items-center w-full gap-2 ">
                                        {/* tropie image */}
                                        <div className={`flex`}>
                                            <img src={iconMedalNone} className="w-[45px]" alt="" />
                                        </div>

                                        <div className="relative w-full p-1 overflow-hidden rounded-l-lg flex justify-between items-center">
                                            <div className="bg-gradient-to-r from-white/10 via-white/10 to-transparent absolute inset-0 animate-fade-green"></div>
                                            <h3 className="relative text-white/70 text-[14px] z-10">{ref.ReferralID}</h3>
                                            <h3 className="relative text-white/70  z-10 font-bold rounded-full w-[35px] h-[35px] justify-center items-center flex text-[10px] border border-white/50">{ref.TotalPoint}</h3>
                                        </div>
                                    </div>

                                </div>

                            </div>
                        })}
                    </div>
                </div>

            </div>
        </div>
    )
}

export default ReferralsComponent